
const PrivacyPolicy = () => {
  return(
    <div className="offcanvas-wrap">
  <section className="py-15 py-xl-20 pb-xl-15 bg-light">
    <div className="container mt-10">
      <h1>Privacy Policy</h1>
      <p className="lead mt-3">
        Important notice about how we handle your personal information.
      </p>
    </div>
  </section>
  <section className="py-15 py-xl-20 legal-content">
    <div className="container">
      <p>
        This Privacy Policy describes how FD121 Limited (
        <span className="fw-bold">FD121</span>,{" "}
        <span className="fw-bold">we</span>, <span className="fw-bold">us</span>{" "}
        or <span className="fw-bold">our</span>) collects, uses, stores, shares,
        processes, discloses, and protects your information when you use our
        Services (as defined below).
      </p>
      <p>
        The <span className="fw-bold">Services</span> shall mean all services
        provided by FD121 Limited pursuant to the FD121 Account User Agreement
        and FDD Terms, and all other communications or other engagement between
        you and FD121, which may be subject to change from time to time.
      </p>
      <p>
        References to <span className="fw-bold">you</span> or{" "}
        <span className="fw-bold">your</span> include the representatives or
        delegates who act on behalf of Users of FD121 Accounts.
      </p>
      <p>
        This Privacy Policy provides a general overview of our privacy practices
        regarding your information. It is important that you read this Privacy
        Policy.
      </p>
      <ol>
        <li>
          <h2>The Data We Collect About You</h2>
          <p>
            Personal data means any information (a) relating directly or
            indirectly to a living individual; (b) from which it is practicable
            for the identity of the individual to be directly or indirectly
            ascertained; and (c) in a form in which access to or processing of
            the data is practicable. It does not include data where the identity
            of the individual has been removed such that they cannot be
            identified (anonymous data).
          </p>
          <p>
            We may collect, use, store, process, and transfer different kinds of
            your personal data:
          </p>
          <ol type="a">
            <li>
              <span className="fw-bold">Identity Data</span> includes first
              name, middle name, maiden name, last name, title, tax ID, marital
              status, date of birth, gender, identity document number,
              nationality and any other information contained in any
              identification documents you provide to us.
            </li>
            <li>
              <span className="fw-bold">Contact Data</span> includes billing
              address, residential address, email address and telephone numbers.
            </li>
            <li>
              <span className="fw-bold">Financial Data</span> includes bank
              account details.
            </li>
            <li>
              <span className="fw-bold">Transaction Data</span> includes details
              of Services activity such as the amount, date, time, recipient for
              each transaction.
            </li>
            <li>
              <span className="fw-bold">Technical Data</span> includes your
              internet protocol (IP) address, login data, browser type and
              version, time zone setting and location, browser plug-in types and
              versions, operating system and platform and other technology on
              the devices you use to access the Services.
            </li>
            <li>
              <span className="fw-bold">Profile Data</span> includes your
              username or similar identifier, password, your preferences,
              feedback and any survey responses.
            </li>
            <li>
              <span className="fw-bold">Usage Data</span> includes information
              about how you use the Services.
            </li>
            <li>
              <span className="fw-bold">Marketing and Communications Data</span>{" "}
              includes your preferences in receiving marketing from us and our
              third parties and your communication preferences.
            </li>
            <li>
              <span className="fw-bold">Survey and Customer Feedback Data</span>{" "}
              includes information you provide in any survey or feedback forms
              on Services.
            </li>
            <li>
              <span className="fw-bold">Location Data</span> includes
              information about your device location.
            </li>
          </ol>
          <p>
            We also collect, use and share for any purpose aggregated data such
            as statistical or demographic data.
          </p>
          <ol>
            <li>
              <h3>Verification and Compliance Checks</h3>
              <p>
                During registration for a FD121 Account or otherwise during the
                course of your use of the Services, we may perform certain
                verification or compliance checks. We carry out these checks in
                order to detect or prevent any unlawful or fraudulent acts and
                to comply with our legal obligations.
              </p>
            </li>
            <li>
              <h3>If You Fail to Provide Personal Data</h3>
              <p>
                Where we need to collect personal data by law, or under the
                terms of a contract we have with you and you fail to provide
                that data when requested, we may not be able to perform the
                contract we have or are trying to enter into with you (i.e. to
                provide the Services to you). In this case, we may have to close
                the FD121 Account or limit or restrict your access to other
                Services, in accordance with the terms of the FD121 Account User
                Agreement, but we will notify you if this is the case at the
                time.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>How We Collect Your Personal Data</h2>
          <p>
            We use different methods to collect data from and about you
            including through:
          </p>
          <ol type="a">
            <li>
              Direct interactions. You may give us your Identity, Contact,
              Financial, Transaction, Profile, Usage, Marketing and
              Communications, and Location Data by filling in forms or by
              corresponding with us by post, phone, email, or otherwise. This
              includes personal data you provide when:
              <ol type="i">
                <li>you create or use the FD121 Account;</li>
                <li>you send a wire transfer to FD121;</li>
                <li>
                  we need to request more information to verify your identity or
                  to meet our legal obligations;
                </li>
                <li>you request marketing to be sent to you;</li>
                <li>you participate in a survey; or</li>
                <li>you give us some feedback.</li>
              </ol>
            </li>
            <li>
              Automated technologies or interactions. When you interact with our
              Services, we may automatically collect Usage and Technical Data
              about your equipment, browsing actions and patterns. We collect
              this personal data by using cookies, server logs and other similar
              technologies. We may also receive Technical Data about you if you
              visit other websites employing our cookies.{" "}
            </li>
            <li>
              Third parties or publicly available sources. We may receive
              personal data about you from various third parties including:
              <ol type="i">
                <li>Technical Data from analytics providers;</li>
                <li>
                  Contact, Financial and Transaction Data from providers of
                  technical or payment services;
                </li>
                <li>
                  Identity and Contact Data from verification or compliance
                  services providers; and
                </li>
                <li>
                  In providing personal data of any individual (other than
                  yourself) to us, you agree that you have obtained consent from
                  such individuals to disclose their personal data to us for
                  processing.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>How We Use Your Personal Data</h2>
          <p>
            We collect all of the information in Section 1 (The Data we Collect
            About You) so that we can provide you with the Services. The
            information we collect allows us to:
          </p>
          <ol type="a">
            <li>Deliver the full functionality of the Services;</li>
            <li>
              Keep in touch with you - whether it be for providing customer
              service, notifying you of new features or updates to the Services,
              or providing security notices or information;
            </li>
            <li>
              Verify your identity so that we can prevent fraud or unauthorized
              activity, and to comply with applicable laws; and
            </li>
            <li>
              Fulfill any other purpose for which the information was collected.
            </li>
          </ol>
          <p>
            We will never share, sell or rent your personal data to third
            parties. We may combine your information with information we collect
            from other companies and use it to improve and personalize the
            Services, as well as our content and advertising.
          </p>
        </li>
        <li>
          <h2>Purposes for Which We Will Use Your Personal Data</h2>
          <p>
            We have set out below a description of the ways we plan to use your
            personal data:
          </p>
          <ol type="a">
            <li>To create and register the FD121 Account.</li>
            <li>To deliver the full functionality of the Services.</li>
            <li>
              To manage our relationship with you which will include notifying
              you about changes to our terms or Privacy Policy; and asking you
              to take part in surveys.
            </li>
            <li>
              To verify your identity and detect and prevent fraudulent or other
              unauthorized activities.
            </li>
            <li>
              To administer and protect the Services (including troubleshooting,
              data analysis, testing, system maintenance, support, reporting and
              hosting of data).
            </li>
            <li>
              To deliver relevant website and platform content and
              advertisements to you and measure or understand the effectiveness
              of the advertising we serve to you.
            </li>
            <li>
              To use data analytics to improve our website, platform, services,
              marketing, customer relationships and experiences.
            </li>
            <li>
              To improve our product or services and develop new products or
              services.
            </li>
            <li>
              To make suggestions and recommendations to you about products or
              services that may be of interest to you through marketing
              communications.
            </li>
            <li>
              To comply with laws, rules or regulations that are applicable to
              us.
            </li>
            <li>
              To meet disclosure requirements or respond to requests from our
              regulators or other government authorities in compliance with
              applicable laws and regulations.
            </li>
            <li>
              To commence, defend or otherwise participate in any legal or
              administrative proceedings or inquiry before any court or
              competent authority.
            </li>
          </ol>
        </li>
        <li>
          <h2>Change of Purpose</h2>
          <p>
            We will only use your personal data in accordance with the purposes
            described in this Privacy Policy and as provided for under
            applicable law for the purposes for which we collected it. If we
            need to use your personal data for a new purpose or a purpose
            unrelated to the original purpose, we will seek your express
            consent.
          </p>
        </li>
        <li>
          <h2>Third-party Links</h2>
          <p>
            The Services may include links to third-party websites, plug-ins and
            applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. We are
            not responsible for the data collection and processing practices of
            the third party and use of any third party websites is subject to
            their privacy policies. When you leave our website or platform, we
            encourage you to read the privacy notice of every website you visit.
          </p>
        </li>
        <li>
          <h2>Marketing</h2>
          <ol>
            <li>
              <h3>Marketing Communications from Us</h3>
              <p>
                We may use your Identity, Contact, Technical, Profile and Usage
                Data to form a view on what we think you may want or need, or
                what may be of interest to you. This is how we decide which
                products, services and offers may be relevant for you (we call
                this marketing).
              </p>
              <p>
                You will only receive marketing communications from us if you
                have requested such information from us or provided us with your
                details when you registered for a promotion and you have opted
                in to receiving that marketing.
              </p>
            </li>
            <li>
              <h3>Opting Out</h3>
              <p>
                You can ask us to stop sending you marketing messages at any
                time by following the opt-out links on any marketing message
                sent to you or by contacting us at any time.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>Cookies</h2>
          <p>
            You can set your browser to refuse all or some browser cookies, or
            to alert you when websites set or access cookies. If you disable or
            refuse cookies, please note that some parts of our website and
            platform may become inaccessible or not function properly.
          </p>
        </li>
        <li>
          <h2>How We Share Your Personal Data</h2>
          <ol>
            <li>
              <h2>Sharing with Third Parties</h2>
              <p>
                We may have to share your personal data with the parties set out
                below for the purposes set out in Section 4 above:
              </p>
              <ol type="a">
                <li>Banking partners.</li>
                <li>Verification service providers and credit bureaus.</li>
                <li>
                  Our agents, contractors, and service providers, including
                  service providers for depositary or custodial services, data
                  storage, onboarding, customer service platforms, accounting
                  and invoicing, IT, communication channels, security, fraud
                  detection, and other third parties to whom we outsource our
                  functions.
                </li>
                <li>
                  Law enforcement agencies, governmental bodies, authorities and
                  regulators.
                </li>
                <li>Our group companies.</li>
                <li>Our business partners.</li>
                <li>
                  Professional advisers who provide consultancy, banking, legal,
                  compliance, insurance or accounting services to us
                </li>
                <li>
                  Actual or proposed assignee or participant or sub-participant
                  or transferee of, or any person taking or wishing to take
                  over, all or any part of our assets or business.
                </li>
                <li>
                  Other third parties whom you specifically authorize us to do
                  so.
                </li>
                <li>
                  Any other persons under a duty of confidentiality to us for
                  any of purposes set out in Section 4.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>International Transfers</h2>
          <p>
            We may transfer your personal data to other countries, which may
            have adopted different data protection standards. When transferring
            data across borders, we take measures to comply with applicable data
            protection laws related to such transfer.
          </p>
        </li>
        <li>
          <h2>How We Protect Your Personal Data</h2>
          <p>
            We protect your information using physical, technical, and
            administrative security measures to reduce the risks of loss,
            misuse, unauthorized access, disclosure and alteration. Some of the
            safeguards we use are firewalls and data encryption, physical access
            controls to data centers and information access authorisation
            controls.
          </p>
        </li>
        <li>
          <h2>Data Retention</h2>
          <p>
            We will only retain your personal data for as long as necessary to
            fulfill the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements.
          </p>
        </li>
        <li>
          <h2>
            Changes to the Privacy Policy and Your Duty to Inform Us of Changes
          </h2>
          <p>
            We may change this Privacy Policy from time to time. When we do make
            updates, we will let you know by changing the date at the top of
            this Privacy Policy. If it is a more extensive or significant
            update, we will send you a notification or post a notice on our
            website and platform. If you ever have any questions about changes
            made to the Privacy Policy, just reach out to us.
          </p>
        </li>
        <li>
          <h2>
            Your Right to Request Access and Correction to Your Personal Data
          </h2>
          <p>
            You have the right to check whether we hold your personal data and
            access such data; and to require us to correct any personal data
            relating to you that is inaccurate.
          </p>
          <p>
            If you wish to exercise any of the rights set out above, please
            contact our data protection officer at{" "}
            <a href="/cdn-cgi/l/email-protection#c2b2b0abb4a3a1bb82a4abb0b1b6a6aba5abb6a3aeaea3a0b1eca1adaf">
              <span
                className="__cf_email__"
                data-cfemail="6717150e1106041e27010e151413030e000e13060b0b0605144904080a"
              >
                [email&nbsp;protected]
              </span>
            </a>
            .
          </p>
          <p>
            You may charge a reasonable fee for your access and correction of
            your personal data (or to exercise any of the other rights).
            However, if your request is clearly unfounded, repetitive or
            excessive, we may refuse to comply with your request in these
            circumstances.
          </p>
        </li>
      </ol>
    </div>
  </section>
</div>
  )
}

export default PrivacyPolicy;