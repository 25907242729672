
const FDDTerms = () => {
  return(
    <div className="offcanvas-wrap">
  <section className="py-15 py-xl-20 pb-xl-15 bg-light">
    <div className="container mt-10">
      <h1>FDD Terms</h1>
    </div>
  </section>
  <section className="py-15 py-xl-20 legal-content">
    <div className="container">
      <p>
        To the extent that you (you, your, or User) have a FD121 Account, these
        FDD Terms (Terms) add to the FD121 Account User Agreement in respect of,
        and that governs your use of, the FD121 Account for FDD Services (as
        defined in Section 1 below). To the extent you do not have a FD121
        Account but hold FDD, these Terms still apply to your holding and use of
        FDD where applicable.
      </p>
      <p>
        By obtaining and using FDD, you expressly agree to these Terms,
        regardless of whether or not you are a User, and you acknowledge that
        you have reviewed and understand each of the disclosures made in this
        section.
      </p>
      <p>
        By holding or using FDD, or using any of the FDD Services, you agree
        that you have read, understood and accept all of the terms and
        conditions contained in these Terms, as well as our Privacy Policy, and
        you acknowledge and agree that you will be bound by these terms and
        policies.
      </p>
      <p>
        Section 20 of these Terms governs how these Terms may be amended; the
        date of the last update is set forth at the top of these Terms.
      </p>
      <ol>
        <li>
          <h2>Interpretation</h2>
          <ol>
            <li>
              Authority means any nation, any political subdivision thereof,
              whether state or local, any international organization, and any
              agency, authority, instrumentality, judicial or administrative,
              regulatory body, law enforcement body, securities or futures
              exchange, court, central bank or other entity exercising
              executive, legislative, judicial, taxing, regulatory or
              administrative powers or functions of or pertaining to government;
            </li>
            <li>
              Dispute means any dispute, claim, difference or controversy
              arising out of, relating to or having any connection with these
              Terms, including any dispute as to its existence, validity,
              interpretation, performance, breach or termination or the
              consequences of its nullity and any dispute relating to any
              non-contractual obligations arising out of or in connection with
              it;
            </li>
            <li>
              Dollars means dollars (or their equivalent) of the fiat currency
              which the FDD is denominated in;
            </li>
            <li>Downtime has the meaning in Section 7.2;</li>
            <li>
              FD121 means FD121 Limited, a private company limited by shares
              incorporated in Hong Kong;
            </li>
            <li>
              FD121 Account means one or more accounts in your name opened with
              FD121;
            </li>
            <li>
              FD121 Account User Agreement means the agreement between a User
              and FD121 in respect of the use of Services provided by FD121
              under the FD121 Account;
            </li>
            <li>
              FDD means the different stablecoins backed by various fiat
              currencies, issued by FD121 from time to time;
            </li>
            <li>FDD Reserves means the reserves to FDD;</li>
            <li>
              FDD Supported Blockchains means the blockchains on which FDD
              operate as referred to in Section 2.1;
            </li>
            <li>
              FDUSD means an FDD which is a U.S. Dollar Coin issued by FD121
              that maintains a value of 1 U.S. Dollar subject to these Terms;
            </li>
            <li>FDUSD Reserves has the meaning in Section 2.3;</li>
            <li>Holder has the meaning in Section 3.5;</li>
            <li>
              Hong Kong means the Hong Kong Special Administrative Region of the
              People's Republic of China;
            </li>
            <li>
              Liability means any loss, damage, cost, charge, claim, demand,
              expense, judgment, action, proceeding or other liability
              whatsoever (including, without limitation, in respect of Taxes,
              Tax Rules, duties, levies, imposts and other charges) and
              including any value added tax or similar tax charged or chargeable
              in respect thereof and legal fees and expenses on a full indemnity
              basis;
            </li>
            <li>
              Platform means a technology platform where Services are provided
              in respect of a FD121 Account;
            </li>
            <li>Prohibited Transactions has the meaning in Section 16.2;</li>
            <li>Restricted Activities has the meaning in Section 16.1;</li>
            <li>Restricted Persons has the meaning in Section 4.2;</li>
            <li>
              Rules means the rules or regulations of any clearing system, the
              rules, operating procedures or market practice of any relevant
              stock exchange or market, any statute, law, regulation, ordinance,
              rule, judgment, order, decree, permit, concession, grant,
              franchise, license, agreement (whether concluded voluntarily or
              involuntarily), directive, requirement of, or other governmental
              restriction or any similar binding form of decision of or
              determination by or with, or any binding interpretation or
              administration of any of the foregoing by or with, any Authority,
              whether now or hereafter in effect.
            </li>
            <li>Sanctions has the meaning in Section 4.2;</li>
            <li>
              Tax means any tax, levy, impost, duty or other charge or
              withholding of a similar nature, and any penalty or interest
              payable in connection with any failure to pay or any delay in
              paying any of the same;
            </li>
            <li>
              Terms means these terms in respect of, and that governs the use
              of, the FD121 Account for FDD Services, and in respect of, and
              that governs the holding and use of FDD;
            </li>
            <li>Third Party Content has the meaning in Section 23;</li>
            <li>USD means U.S. Dollar; and</li>
            <li>
              User means a user of Services provided by FD121 under the FD121
              Account.
            </li>
          </ol>
        </li>
        <li>
          <h2>Interpretation</h2>
          <ol>
            <li>
              FDD are digital tokens issued by FD121 Limited (FD121, we, or us)
              that operate on each of the blockchains listed at
              https://firstdigitallabs.com (collectively, the FDD Supported
              Blockchains. For the avoidance of doubt, these Terms only apply to
              FDD issued by FD121.
            </li>
            <li>
              Each FDD issued is backed by an equivalent amount of assets held
              by FD121. For each FDD issued by FD121 and remaining in
              circulation, FD121 will hold either one Dollar or an equivalent
              amount assets denominated in either USD or the currency in which
              the FDD is denominated in (the FDD Reserves).
            </li>
            <li>
              FDUSD is an FDD backed by an equivalent amount of USD- and U.S.
              Dollar-denominated assets held by FD121. For every FDUSD issued by
              FD121 and remaining in circulation, FD121 will hold either one
              U.S. Dollar (USD), or an equivalent amount of USD-denominated
              assets (the FDUSD Reserves).
            </li>
            <li>FDD Reserves are held for FD121 by qualified custodians.</li>
            <li>
              USD-denominated assets in the FDD Reserves are held in the form of
              cash, and securities determined by FD121 from time to time as
              permitted under applicable Rules.
            </li>
          </ol>
        </li>
        <li>
          <h2>Scope of FDD and Key Terms</h2>
          <ol>
            <li>
              To the extent you have agreed to, and are subject to, the FD121
              Account User Agreement, FD121 makes available the following
              FDD-related Services to you: (i) buy FDD from FD121 with the fiat
              currency which the FDD is denominated in; and (ii) sell FDD to
              FD121 for the fiat currency which the FDD is denominated in
              (collectively, the FDD Services). Your use of the FDD Services is
              subject to these Terms. Any of the FDD Services can be
              discontinued at any time in accordance with Section 1 of these FDD
              Terms.
            </li>
            <li>
              You understand and agree that you may only buy FDD from FD121 with
              the fiat currency which the FDD is denominated in and sell FDD to
              FD121 for the fiat currency which the FDD is denominated in, in
              your FD121 Account directly with FD121 to the extent that you have
              a FD121 Account in good standing.
            </li>
            <li>
              You may sell FDD with FD121 unless and until you open a FD121
              Account. Eligibility for and requirements related to opening a
              FD121 Account are set forth in the FD121 Account User Agreement.
            </li>
            <li>
              Your use of FDD and FDD Services (as applicable), is subject to
              these Terms and FD121’s obligations hereunder are conditional on
              you complying with its provisions.
            </li>
            <li>
              You understand and agree that sending FDD to another address
              automatically transfers and unconditionally assigns to the owner
              of that address (a Holder), and any subsequent Holder, the right
              to sell FDD for the fiat currency which the FDD is denominated in
              to FD121 so long as the Holder is eligible to, and does, register
              a FD121 Account. For the avoidance of doubt, if a Holder is not
              eligible to register a FD121 Account, or fails to do so, such
              Holder is not entitled to sell FDD to FD121.{" "}
            </li>
            <li>
              Each FDD is intended to maintain a value of 1 Dollar. In order to
              issue 1 FDD, a corresponding 1 Dollar (or an equivalent amount of
              assets) is held in the FDUSD Reserves.{" "}
            </li>
            <li>
              FD121 endeavours to buy 1 FDD for 1 Dollar however your ability to
              sell 1 FDD to us for 1 Dollar is conditional on (i) your
              possession of a corresponding amount of FDD, (ii) you maintaining
              a FD121 Account, (iii) no violation of these Terms and the FD121
              Account User Agreement, and (iv) no applicable Rules, action,
              pending or otherwise, by a regulator, law enforcement or a court
              of competent jurisdiction that would restrict sale.
            </li>
            <li>
              FD121 may in its discretion but at all times subject to applicable
              Rules, cancel, suspend, restrict, defer or limit the buying and
              selling of FDD in any manner it deems reasonable including
              establishing individual or aggregate transaction limits on the
              buying and selling of FDD or other transactions that you initiate
              using your FD121 Account during any specified time period as
              publicized by us from time to time, in the following
              circumstances: (i) sufficient FDD Reserves cannot be realised at
              an appropriate price or on adequate terms or otherwise due to
              circumstances beyond FD121’s control; (ii) FD121 is unable to meet
              sale requests due to circumstances beyond its reasonable control;
              (iii) receipt of significant sale requests or market volatility
              that threatens the liquidity, solvency, value, or viability of
              FDD, including without limitation due to the closure of a relevant
              digital assets exchange platform, any suspension of or limitation
              imposed on trading by a relevant digital assets exchange platform
              or otherwise, an emergency or other similar state of affairs or
              the requirement of any law); or (iv) other similar circumstances.
              For the avoidance of doubt, the buying and selling of FDD in this
              context refers to the buying and selling of FDD between FD121 and
              a User maintaining a FD121 Account, but not with Holders
              generally.
            </li>
            <li>
              FDD Reserves are owned beneficially by FD121. While FD121 may hold
              the FDD Reserves in interest-bearing accounts or other
              yield-generating instruments, you acknowledge that you are not
              entitled, whether by way of contract, trust or otherwise, to such
              assets or any interest or other returns whatsoever earned by FD121
              or its custodian on FDD Reserves. As set out in Section 2 above,
              FDD is backed by an equivalent amount of assets held by FD121. For
              each FDD issued by FD121 and remaining in circulation, FD121 will
              hold either one Dollar or an equivalent amount of assets
              denominated in either USD or the currency in which the FDD is
              denominated in.
            </li>
            <li>
              FDD does not itself generate any profits, income, interests,
              payments or returns for holders of FDD and only represents your
              right to sell FDD for an equivalent amount of fiat currency which
              the FDD is denominated in through your account with FD121.
            </li>
          </ol>
        </li>
        <li>
          <h2>Applicable Rules</h2>
          <ol>
            <li>
              Your holding and use of FDD, and any use of the FDD Services, is
              subject to all applicable Rules, including, without limitation,
              all applicable tax, anti-money laundering (AML) and
              counter-terrorist financing (CTF) provisions and sanctions. You
              agree to act in compliance with and be legally bound by these
              Terms and all applicable Rules. These Terms are conditional on
              your continued compliance at all times with these Terms and all
              applicable Rules.
            </li>
            <li>
              Applicable Rules require us to prevent Restricted Persons from
              holding FDD or using FDD Services. A Restricted Person means any
              person subject to comprehensive sanctions under economic sanctions
              programs administered in the jurisdictions where we conduct
              business (Sanctions).
            </li>
          </ol>
        </li>
        <li>
          <h2>Eligibility; Limitations</h2>
          <ol>
            <li>
              FD121 Accounts, FDD Services and support for FDD are currently
              only available to individuals and institutions (as applicable)
              located in supported jurisdictions as publicised by us from time
              to time.
            </li>
            <li>
              By holding or using FDD, or accessing or using the FDD Services,
              you further represent and warrant that:
              <ol type="a">
                <li>
                  you are at least 18 years old, are not a Restricted Person,
                  and are not holding FDD on behalf of a Restricted Person.
                </li>
                <li>
                  you will not be using FDD or the FDD Services (as applicable)
                  for any illegal activity, including, but not limited to,
                  illegal gambling, money laundering, fraud, blackmail,
                  extortion, ransoming data, terrorism financing, other violent
                  activities or any prohibited market practices, including, but
                  not limited to, those listed under Section 16.
                </li>
              </ol>
            </li>
            <li>
              You also understand that there are additional representations and
              warranties made by you elsewhere in (or by reference in) these
              Terms and that any misrepresentation by you is a violation of
              these Terms.
            </li>
            <li>
              Notwithstanding the foregoing, FD121 may determine not to make FDD
              or the FDD Services, in whole or in part, available in every
              market, either in its sole discretion or due to applicable Rules,
              depending on your location.
            </li>
          </ol>
        </li>
        <li>
          <h2>AML and CTF Compliance</h2>
          <p>
            Our AML and CTF procedures are guided by all applicable Rules
            regarding AML and CTF. These standards are designed to prevent the
            use of the FDD Services for money laundering or terrorist financing
            activities. We take compliance very seriously and it is our policy
            to take all necessary steps to prohibit fraudulent transactions,
            report suspicious activities, and actively engage in the prevention
            of money laundering and any related acts that facilitate money
            laundering, terrorist financing or any other financial crimes.
          </p>
        </li>
        <li>
          <h2>FDD Supported Blockchains and Smart Contract Modifications</h2>
          <ol>
            <li>
              FDD operates on FDD Supported Blockchains. FD121 does not have any
              ability or obligation to prevent or mitigate attacks or resolve
              any other issues that might arise with any FDD Supported
              Blockchain. Any such attacks or delays on any FDD Supported
              Blockchain might materially delay or prevent you from sending or
              receiving FDD, and FD121 shall bear no responsibility for any
              losses that result from such issues.
            </li>
            <li>
              Note that in certain circumstances, including, but not limited to,
              a copy or fork of a FDD Supported Blockchain or the identification
              of a security issue with a FDD Supported Blockchain, FD121 may be
              forced to suspend all activities relating to FDD (including buying
              and selling FDD) for an extended period of time until such
              downtime is over and FDD Services can be restored (Downtime). This
              Downtime will likely occur immediately upon a copy or fork of any
              FDD Supported Blockchain, potentially with little to no warning,
              and during this period of Downtime you will not be able to conduct
              various activities involving FDD.
            </li>
            <li>
              FD121 reserves the right to migrate FDD to another blockchain or
              protocol in the future in its reasonable discretion. Upon FD121’s
              request, you agree to take any and all actions reasonably
              necessary to effect the migration of your FDD to another
              blockchain or protocol identified by FD121. If you fail to effect
              such migration, the FDD may not be compatible with your FD121
              Account going forward. FD121 will not be responsible or liable for
              any damages, losses, costs, fines, penalties or expenses of
              whatever nature, whether or not reasonably foreseeable by the
              parties, which you may suffer, sustain or incur, arising out of or
              relating to your failure to effectuate such migration of your FDD
              to another blockchain or protocol identified by FD121.
            </li>
          </ol>
        </li>
        <li>
          <h2>Privacy</h2>
          <p>
            We are committed to protecting your personal information and helping
            you understand exactly how your personal information is being used.
            You should carefully read the FD121 Privacy Policy, as it provides
            details on how your personal information is collected, stored,
            protected, processed, disclosed, and used.
          </p>
        </li>
        <li>
          <h2>Communications</h2>
          <ol>
            <li>
              By entering into these Terms, you agree to receive electronic
              communications and notifications from us by way of e-mail, by
              posting a notice to our website, or through other electronic means
              as we shall reasonably select.
            </li>
            <li>
              These Terms are provided to you and communicated in English. We
              will also communicate with you in English for all matters related
              to FDD and your use of FDD Services. Where we have provided you
              with a translation of the English language version of these Terms,
              you agree that such translation is provided for your convenience
              only and that the English language version of these Terms govern
              your holding and use of FDD, and the FDD Services, as applicable.
            </li>
          </ol>
        </li>
        <li>
          <h2>IP Rights</h2>
          <p>
            All logos related to the FDD Services are either trademarks, or
            registered marks of FD121 or its licensors. Whether or not you have
            a FD121 Account, you may not copy, imitate, or use them without
            FD121's prior written consent. All right, title, and interest in and
            to the FD121 website, the Platform, any content thereon, the FDD
            Services, and all technology and any content created or derived from
            any of the foregoing is the exclusive property of FD121 and its
            licensors.
          </p>
        </li>
        <li>
          <h2>Risk Factors &amp; Risk Disclosures</h2>
          <p>
            By entering into these Terms, you agree that you have read and
            understood, and agree to the risks associated with FDD and the FDD
            Services, non-exhaustively set out in FDD risk factors appendix, as
            updated from time to time.{" "}
          </p>
        </li>
        <li>
          <h2>Third-party Products and Services</h2>
          <ol>
            <li>
              You understand and agree that FD121 does not control any products
              or services sold or offered by third parties using the FDD
              Services or using FDD.{" "}
            </li>
            <li>
              FD121 is not liable for any losses or issues that may arise from
              such third-party transactions, including, but not limited to,
              failure to comply with applicable Rules (including any
              consequences for illegal transactions that might be triggered
              under these Terms), the quality and delivery of such products and
              services, or your satisfaction with any products or services, the
              purchase of which is facilitated by the FDD Services. If you are
              not satisfied with any goods or services purchased from a third
              party using the FDD Services, you must handle those issues
              directly with the third-party seller.
            </li>
          </ol>
        </li>
        <li>
          <h2>Currency Conversions</h2>
          <ol>
            <li>
              If you wire funds to FD121 to buy FDD, you should denominate your
              wire transfer in the currency which the FDD is denominated in.
            </li>
            <li>
              If you transmit your wire in a currency other than in the currency
              which the FDD is denominated in, we will deduct an amount of FDD
              that results following a currency conversion of any fees charged
              for such currency conversion. FD121 is not responsible for any
              processing delays that may result in connection with completing
              such currency conversion. Additionally, FD121’s banking partners
              may not support currencies which FDD is not denominated in, and
              may be forced to reject or return a wire received in a
              non-supported currency.
            </li>
          </ol>
        </li>
        <li>
          <h2>Right to Change/Remove Features or Suspend/Delay Transactions</h2>
          <p>
            We reserve the right to (i) change, suspend, or discontinue any
            aspect of the FDD Services at any time, including hours of operation
            or availability of any feature, without notice and without liability
            and (ii) decline to process any subscription or redemption without
            prior notice and may limit or suspend your use of one or more FDD
            Services at any time, in our sole discretion. Our rights under this
            paragraph are subject to our obligations under applicable Rules and
            licenses, including but not limited to our reasonable suspicion of
            inappropriate or illegal conduct. Suspension of your use of any of
            the FDD Services will not affect your rights and obligations
            pursuant to these Terms. We may, in our sole discretion, delay,
            suspend or cancel issuances or redemptions if we reasonably believe
            the transaction is suspicious, may involve fraud or misconduct,
            violates applicable Rules, or violates the terms of these Terms.
          </p>
        </li>
        <li>
          <h2>Insufficient Funds</h2>
          <p>
            If a payment made to buy FDD is reversed by your bank or FD121’s
            banking partners after a tokenization of FDD is completed, FD121
            will initiate a sale of FDD and deduct such FDD from your FD121
            Account in accordance with a court order or other legal or
            regulatory directions. Furthermore, subject to our investigation of
            the reversal request, FD121 reserves the right but is not obligated
            to (i) initiate a sale of FDD and deduct such FDD from your FD121
            Account, (ii) deduct such amounts from future FDD issuances to your
            FD121 Account, (iii) suspend your FD121 Account and access to the
            FDD Services until resolved, and (iv) pursue legal action or any
            other means of recovery legally available.
          </p>
        </li>
        <li>
          <h2>Restricted Activities and Prohibited Transactions</h2>
          <ol>
            <li>
              In connection with your holding or use of FDD, or the FDD Services
              (as applicable), you hereby agree that you will not:
              <ol type="a">
                <li>
                  Send FDD to individuals and institutions located in
                  jurisdictions other than in supported jurisdictions;
                </li>
                <li>
                  Violate (or assist any other party in violating) any
                  applicable Rules;
                </li>
                <li>
                  Intentionally try to defraud (or assist in the defrauding of)
                  FD121 or other Users;
                </li>
                <li>Provide false, inaccurate, or misleading information;</li>
                <li>
                  Take any action that interferes with, intercepts, or
                  expropriates any system, data, or information;
                </li>
                <li>
                  Partake in any transaction involving the proceeds of illegal
                  activity;
                </li>
                <li>
                  Transmit or upload any virus, worm, or other malicious
                  software or program;
                </li>
                <li>
                  Attempt to gain unauthorized access to other FD121 Accounts,
                  the FD121 website, or any related networks or systems;
                </li>
                <li>
                  Use the FDD Services on behalf of any third party or otherwise
                  act as an intermediary between FD121 and any third parties;
                </li>
                <li>
                  Collect any User information from other Users, including,
                  without limitation, email addresses;
                </li>
                <li>
                  Defame, harass, or violate the privacy or intellectual
                  property rights of FD121 or any other Users; or
                </li>
                <li>
                  Upload, display or transmit any messages, photos, videos or
                  other media that contain illegal goods, violent, obscene or
                  copyrighted images or materials (such activities, Restricted
                  Activities).
                </li>
              </ol>
            </li>
            <li>
              Using FDD or the FDD Services for transactions related to the
              following is prohibited, and FD121 reserves the right to monitor
              and, if appropriate, block or otherwise prevent transactions that
              relate to:
              <ol type="a">
                <li>Any Restricted Persons;</li>
                <li>
                  Weapons of any kind, including but not limited to firearms,
                  ammunition, knives, explosives, or related accessories;
                </li>
                <li>
                  Controlled substances, including but not limited to narcotics,
                  prescription drugs, steroids, or related paraphernalia or
                  accessories, unless licensed and authorized by the
                  jurisdiction in which the User is based as well as by the
                  jurisdiction in which the transaction takes place;
                </li>
                <li>
                  Gambling activities including but not limited to sports
                  betting, casino games, horse racing, dog racing, games that
                  may be classified as gambling, or other activities that
                  facilitate any of the foregoing, unless licensed and
                  authorized by the jurisdiction in which the User is based as
                  well as by the jurisdiction in which the transaction takes
                  place;
                </li>
                <li>Money-laundering or terrorist financing;</li>
                <li>
                  Any sort of Ponzi scheme, pyramid scheme, or multi-level
                  marketing program;
                </li>
                <li>
                  Goods or services that infringe or violate any copyright,
                  trademark, or proprietary rights under the laws of any
                  jurisdiction;
                </li>
                <li>
                  Credit repair services, or other services that may present
                  consumer protection risks;
                </li>
                <li>
                  Court ordered payments, structured settlements, tax payments,
                  or tax settlements;
                </li>
                <li>Any unlicensed money transmitter activity;</li>
                <li>Layaway systems, or annuities;</li>
                <li>
                  Counterfeit goods, including but not limited to fake or
                  “novelty” IDs;
                </li>
                <li>
                  Wash trading, front-running, insider trading, market
                  manipulation or other forms of market-based fraud or deceit;
                </li>
                <li>
                  Purchasing goods of any type from “Darknet” markets, or any
                  other service or website that acts as a marketplace for
                  illegal goods (even though such marketplace might also sell
                  legal goods); or
                </li>
                <li>
                  Any other matters, goods, or services that from time to time
                  we communicate to you that are unacceptable and which, for
                  example, may be restricted by our and your financial
                  institution, wallet service provider, or payment partners
                  (such transactions, Prohibited Transactions).
                </li>
              </ol>
            </li>
            <li>
              In the event that FD121 becomes aware or suspects you are making
              any such Prohibited Transactions, FD121 will consider it to be a
              violation of these Terms and may also suspend or terminate your
              FD121 Account, which can result in the potential forfeit of any
              funds otherwise eligible for redemption.
            </li>
          </ol>
        </li>
        <li>
          <h2>Indemnification; Release</h2>
          <ol>
            <li>
              You agree to indemnify and hold FD121, its affiliates, and service
              providers, and each of their officers, directors, agents, joint
              venturers, employees, and representatives harmless from any claim
              or demand (including attorneys’ fees and any Liabilities, fines,
              fees or penalties imposed by any regulatory authority) arising out
              of your breach of these Terms, your violation of any law or
              regulation or your holding or use of FDD.
            </li>
            <li>
              If you have a Dispute with one or more Users or third parties, you
              release FD121 (and its affiliates and service providers, and each
              of their officers, directors, agents, joint ventures, employees
              and representatives) from all claims, demands, and damages (actual
              and consequential) of every kind and nature arising out of or in
              any way connected with such Disputes.
            </li>
          </ol>
        </li>
        <li>
          <h2>Limitation of Liability; No Warranty</h2>
          <ol>
            <li>
              You expressly understand and agree that FD121 and our affiliates
              and service providers (including, for the avoidance of doubt, any
              custodian), and their respective officers, directors, agents,
              joint venturers, employees, and representatives will, to the
              extent permitted by applicable Rules, not be liable for any
              indirect, incidental, special, consequential, exemplary damages,
              or damages for loss of profits including but not limited to,
              damages for loss of goodwill, use, data, or other intangible
              losses (even if FD121 has been advised of the possibility of such
              damages), whether based on contract, tort, negligence, strict
              liability, or otherwise, resulting from: (i) the use or the
              inability to hold or use FDD; (ii) the cost of procurement of
              substitute goods and services resulting from any goods, data,
              information, or services purchased or obtained or messages
              received or transactions entered into involving FDD; (iii)
              unauthorized access to or alteration of your transmissions or
              data; or (iv) any other matter involving FDD.
            </li>
            <li>
              FDD is provided "as is" and without any representation or
              warranty, whether express, implied or statutory. FD121, our
              affiliates, and our respective officers, directors, agents, joint
              venturers, employees, and suppliers specifically disclaim any
              implied warranties of title, merchantability, fitness for a
              particular purpose or non-infringement. FD121 makes no warranty
              that (i) the FDD will meet your requirements, (ii) the FDD will be
              uninterrupted, timely, secure, or error-free, or (iii) the quality
              of any products, services, information, or other material
              purchased or obtained by you will meet your expectations.
            </li>
          </ol>
        </li>
        <li>
          <h2>Force Majeure</h2>
          <p>
            FD121 shall have no liability for any failure or delay resulting
            from any condition beyond our reasonable control, including acts of
            God, terrorism, pandemics, shortage of supply, labour difficulties
            (including strikes), war, civil unrest, fire, floods, electrical
            outages, equipment or transmission failures, internet interruptions,
            vendor failures (including information technology providers), or
            other similar causes.
          </p>
        </li>
        <li>
          <h2>Amendments</h2>
          <ol>
            <li>
              FD121 may amend any portion of these Terms at any time. The
              revised version of these Terms with an updated revision date will
              be available to you by ways set out in Section 9.1 above. The
              changes will become effective, and shall be deemed accepted by
              you, the first time you access or use FDD or the FDD Services
              after the initial posting of the revised Terms and shall apply on
              a going-forward basis with respect to transactions initiated after
              the posting date. In the event that you do not agree with any such
              modification, your sole and exclusive remedy is to terminate your
              use of the FDD Services and terminate your FD121 Account (if any).
              You agree that we shall not be liable to you or any third party as
              a result of any losses suffered by any modification or amendment
              of these Terms.
            </li>
            <li>
              If the revised Terms includes a material change, we will provide
              you with prior notice via our website and/or email before the
              material change becomes effective. For this purpose, a material
              change means a significant change other than changes that (i) are
              to your benefit, (ii) are required to be made to comply with
              applicable Rules or as otherwise required by one of our
              regulators, (iii) relates to a new product or service made
              available to you, or (iv) otherwise clarifies an existing term.
            </li>
          </ol>
        </li>
        <li>
          <h2>Assignment and Third-party Holders</h2>
          <ol>
            <li>
              You may not transfer or assign these Terms or any rights or
              obligations hereunder, by operation of law or otherwise and any
              such attempted assignment shall be void, subject to the following
              exception. Sending FDD to an address will automatically transfer
              and unconditionally assign to that Holder, and any subsequent
              Holder, the right to sell FDD for the fiat currency in which the
              FDD is denominated in, so long as the Holder is eligible to, and
              does, register a FD121 Account.
            </li>
            <li>
              Each Holder is subject to all terms of these Terms as if a User
              including, but not limited to, the requirements to not engage in
              Restricted Activities or Prohibited Transactions.
            </li>
            <li>
              We reserve the right to freely assign these Terms and the rights
              and obligations of these Terms to any third party at any time
              without notice or consent. If you object to such transfer or
              assignment, you may stop holding and using FDD, you may also stop
              using our FDD Services (where appropriate), and terminate these
              Terms by contacting FD121’s support team and asking us to close
              your FD121 Account.
            </li>
          </ol>
        </li>
        <li>
          <h2>Survival</h2>
          <p>
            Upon termination of these Terms (and termination of your FD121
            Account, to the extent applicable), all rights and obligations of
            the parties that by their nature are continuing will survive such
            termination.
          </p>
        </li>
        <li>
          <h2>Website; Third-party Content</h2>
          <p>
            FD121 strives to provide accurate and reliable information and
            content on the FD121 website, but such information may not always be
            correct, complete, or up to date. FD121 will update the information
            on the FD121 website as necessary to provide you with the most up to
            date information, but you should always independently verify such
            information. The FD121 website may also contain links to third-party
            websites, applications, events or other materials (Third Party
            Content). Such information is provided for your convenience and
            links or references to Third Party Content do not constitute an
            endorsement by FD121 of any products or services. FD121 shall have
            no liability for any losses incurred as a result of actions taken in
            reliance on the information contained on the FD121 website or in any
            Third Party Content.
          </p>
        </li>
        <li>
          <h2>Legal Compliance</h2>
          <p>
            The FDD and FDD Services are subject to AML and CTF provisions and
            Sanctions. By holding or using FDD, or using the FDD Services, you
            represent and warrant that your actions are not in violation of
            Sanctions. Without limiting the foregoing, you may not hold or use
            FDD, or use the FDD Services if you are a Restricted Person or
            intend to transact with or on behalf of any Restricted Persons.{" "}
          </p>
        </li>
        <li>
          <h2>Governing Law; Jurisdiction</h2>
          <p>
            These Terms are governed by the laws of Hong Kong. The courts of
            Hong Kong have exclusive jurisdiction to settle any Dispute and each
            party to these Terms irrevocably submits to the exclusive
            jurisdiction of the courts of Hong Kong. The parties agree that the
            courts of Hong Kong are the most appropriate and convenient courts
            to settle Disputes and accordingly no party will argue to the
            contrary.
          </p>
        </li>
        <li>
          <h2>Miscellaneous</h2>
          <ol>
            <li>
              The failure of FD121 to exercise or enforce any right or provision
              of these Terms shall not constitute a waiver of such right or
              provision.
            </li>
            <li>
              If any provision of these Terms shall be adjudged by any court of
              competent jurisdiction to be unenforceable or invalid, that
              provision shall be limited or eliminated to the minimum extent
              necessary so that these Terms shall otherwise remain in full force
              and effect and remain enforceable between the parties, except as
              specified in Section 20.
            </li>
            <li>
              Furthermore, if any portion of these Terms, whether in whole, or
              in part, shall be adjudged by any court of competent jurisdiction
              to be unenforceable or invalid against certain persons or
              categories of persons that are purportedly bound by these Terms,
              such portion of these Terms shall otherwise remain in full force
              and effect and remain enforceable as to any other persons bound by
              these terms.
            </li>
            <li>
              The headings and explanatory text are for reference purposes only
              and in no way define, limit, construe, or describe the scope or
              extent of such section.
            </li>
            <li>
              These Terms and FD121’s policies governing the holding or use of
              FDD, the use of the FDD Services referenced herein, and the
              Privacy Policy constitute the entire agreement between you and
              FD121 with respect to the holding or use of FDD, and the use of
              the FDD Services.
            </li>
            <li>
              Notwithstanding anything to the contrary set forth in these Terms
              or otherwise, to the extent that the FD121 Account User Agreement
              applies to you, in the event of a conflict between any term set
              forth herein and any term set forth in the FD121 Account User
              Agreement, these Terms shall prevail.
            </li>
          </ol>
        </li>
        <li>
          <h2>Third-party Rights</h2>
          <p>
            A person who is not a party to these Terms shall have no rights
            under the Contracts (Rights of Third Parties) Ordinance (Cap. 623 of
            the Laws of Hong Kong) to enforce or enjoy the benefit of any
            provision of these Terms.
          </p>
        </li>
      </ol>
    </div>
  </section>
</div>
  )
}

export default FDDTerms;