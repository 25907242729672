import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="offcanvas-wrap">
  <section className="overflow-hidden pt-15 pt-xl-20 pb-5 pb-xl-10">
    <div className="container">
      <div className="row align-items-end mb-10 mt-5">
        <div className="col-lg-6">
          <h1>We #buidl currency for the digital economy.</h1>
        </div>
        <div className="col-lg-6">
          <p className="fs-lg mb-1">
            Welcome to First Digital Labs, the cutting-edge research &amp;
            development division of{" "}
            <Link
              to="/"
              className="fw-bold underline"
            >
              First Digital
            </Link>
            , specializing in the innovation and advancement of digital
            currencies.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section style={{ height: "76vh" }}>
    <figure
      className="background"
      style={{
        backgroundImage:
          'url("/workspace/img/photos/80d276cae63d4fa07da0a6223ecb12ac.jpg")'
      }}
    />
  </section>
  <section className="py-15 py-xl-20">
    <div className="container">
      <div className="row g-4 g-xl-6 align-items-start justify-content-between">
        <div className="col-3">
          <h2>Our Focus</h2>
        </div>
        <div className="col-6">
          <p className="lead mb-lg-8">
            At First Digital Labs, we dedicate ourselves to driving
            technological breakthroughs, fostering seamless integration, and
            ensuring the security and efficiency of digital currencies for
            businesses and individuals alike. Join us as we shape the future of
            finance, one digital transaction at a time.
          </p>
          <p>
            Operated by FD121 Limited and an integral part of the First Digital
            Group, First Digital Labs strives to reshape the global financial
            landscape by leveraging the power and stability of cutting-edge
            digital currencies.
          </p>
          <p>
            Our multidisciplinary team at First Digital Labs boasts a diverse
            and rich background, blending expertise in traditional finance with
            deep insights into emerging technologies. This unique combination
            positions us at the forefront of the stablecoin revolution,
            empowering businesses and individuals alike with secure, efficient,
            and seamlessly integrated digital currency solutions.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
  );
}

export default About;