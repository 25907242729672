import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="py-15 py-xl-15 bg-black">
    <div className="container inverted">
      <div className="row g-4 g-xl-6 align-items-start justify-content-between">
        <div className="col-md-6 text-center text-md-start">
          <a
            href="https://firstdigitallabs.com"
            title="Go to First Digital Labs homepage"
          >
            <img
              src="https://firstdigitallabs.com/workspace/img/logo/logo-light.svg"
              className="svg-inject"
              alt="First Digital Labs"
              style={{ height: 37 }}
            />
          </a>
        </div>
        <div className="col-md-2 text-center text-md-start">
          <span className="h6 mb-2 d-block">Transparency</span>
          <ul className="list-unstyled">
            <li className="mb-1">
              <Link to="/legal" className="text-reset text-primary-hover">
                Legal &amp; Compliance
              </Link>
            </li>
            <li className="mb-1">
              <Link
                to="/legal/policies/privacy-policy"
                className="text-reset text-primary-hover"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-2 text-center text-md-start">
          <span className="h6 mb-2 d-block">Company</span>
          <ul className="list-unstyled">
            <li className="mb-1">
              <Link to="/about" className="text-reset text-primary-hover">
                About Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-2 text-center text-md-start">
          <span className="h6 mb-2 d-block">Social Media</span>
          <ul className="list-unstyled">
            <li className="mb-1">
              <a
                href="/"
                className="text-reset text-primary-hover"
                
              >
                Twitter
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="my-5" />
      <p className="eyebrow">Important Disclosures:</p>
      <p className="fs-sm text-muted">
        FD121 Limited is a financial technology company, not a bank. Custody
        services of the reserves are provided by{" "}
        <a href="https://1stDigital.com">
          First Digital Trust Limited
        </a>
        .
      </p>
      <p className="fs-sm text-muted">
        All product and company names are trademarks™ or registered® trademarks
        of their respective holders. All company, product and service names used
        in this website are for identification purposes only. Use of these
        names, trademarks and brands does not imply any affiliation with or
        endorsement by them.
      </p>
      {/* <p className="fs-sm text-muted">
        For additional important risks, disclosures, and information, please
        visit <a href="/legal/">https://firstdigitallabs.com/legal/</a>
      </p> */}
      <p className="fs-sm text-muted">
        Copyright © 2024 FD121 Limited. All Rights Reserved.
      </p>
    </div>
  </footer>
  )
}

export default Footer;