
const UserAgreement = () => {
  return(
    <div className="offcanvas-wrap">
  <section className="py-15 py-xl-20 pb-xl-15 bg-light">
    <div className="container mt-10">
      <h1>FD121 Account User Agreement</h1>
      <p className="lead mt-3">
        Contract between you and FD121 that applies to your use of a FD121
        Account.
      </p>
    </div>
  </section>
  <section className="py-15 py-xl-20 legal-content">
    <div className="container">
      <p>
        This FD121 Account User Agreement (this{" "}
        <span className="fw-bold">Agreement</span>) is a contract between you (
        <span className="fw-bold">you</span> or a{" "}
        <span className="fw-bold">User</span>) and FD121 Limited (
        <span className="fw-bold">FD121</span>,{" "}
        <span className="fw-bold">we</span>, or{" "}
        <span className="fw-bold">us</span>) that applies to your use of a FD121
        account with FD121 (your <span className="fw-bold">FD121 Account</span>
        ). FD121 may offer certain services as described herein (the{" "}
        <span className="fw-bold">Services</span>) on the FD121 Account platform
        (the <span className="fw-bold">Platform</span>). The FDD Terms apply to
        your FD121 Account, and stablecoins backed by fiat currencies, issued by
        FD121 from time to time, as applicable. From and after the date set
        forth above, this Agreement shall govern your use of any FD121 Account.
      </p>
      <p>
        For the avoidance of doubt, your access to the Services is contingent on
        your maintenance of a FD121 Account and access to the Platform.
      </p>
      <p>
        By registering for a FD121 Account or using any of the Services, you
        agree that you have read, understood and accepted all of the terms and
        conditions contained in this Agreement as well as our Privacy Policy,
        and you acknowledge and agree that you will be bound by these agreements
        and policies.
      </p>
      <p>
        Our Services currently only support stablecoins backed by fiat
        currencies, issued by FD121 from time to time (being{" "}
        <span className="fw-bold">FDD</span>). For the avoidance of doubt, where
        used herein, the term <span className="fw-bold">funds</span> includes
        fiat currency and FDD.
      </p>
      <p>
        Section 22 of this Agreement governs how this Agreement may be amended;
        the date of the last update is set forth at the top of this Agreement.
      </p>
      <ol>
        <li>
          <h2>Interpretation</h2>
          <ol>
            <li>
              <span className="fw-bold">Agreement</span> means this FD121
              Account User Agreement, the FDD Terms, and our Privacy Policy,
              each as amended from time to time;
            </li>
            <li>
              <span className="fw-bold">Additional Users</span> has the meaning
              set forth in Section 2.6;
            </li>
            <li>
              <span className="fw-bold">Authority</span> means any nation, any
              political subdivision thereof, whether state or local, any
              international organization, and any agency, authority,
              instrumentality, judicial or administrative, regulatory body, law
              enforcement body, securities or futures exchange, court, central
              bank or other entity exercising executive, legislative, judicial,
              taxing, regulatory or administrative powers or functions of or
              pertaining to government;
            </li>
            <li>
              <span className="fw-bold">Blocked Addresses</span> has the meaning
              in Section 15;
            </li>
            <li>
              <span className="fw-bold">Dispute</span> means any dispute, claim,
              difference or controversy arising out of, relating to or having
              any connection with this Agreement, including any dispute as to
              its existence, validity, interpretation, performance, breach or
              termination or the consequences of its nullity and any dispute
              relating to any non-contractual obligations arising out of or in
              connection with it;
            </li>
            <li>
              <span className="fw-bold">Downtime</span> has the meaning in
              Section 10.3;
            </li>
            <li>
              <span className="fw-bold">Error</span> has the meaning in Section
              20.4;
            </li>
            <li>
              <span className="fw-bold">FDD</span> means the different
              stablecoins backed by such various fiat currencies issued by FD121
              from time to time;
            </li>
            <li>
              <span className="fw-bold">FDD Services</span> means the Services
              provided by FD121 under any FD121 Account involving FDD as set out
              in FDD Terms;
            </li>
            <li>
              <span className="fw-bold">FD121</span> means FD121 Limited, a
              private company limited by shares incorporated in Hong Kong;
            </li>
            <li>
              <span className="fw-bold">FD121 Account</span> means one or more
              accounts in your name opened with FD121;
            </li>
            <li>
              <span className="fw-bold">FDD Terms</span> means the terms in
              respect of, and that govern the use of, the FD121 Account for FDD
              Services, and in respect of, and that govern the holding and use
              of FDD;
            </li>
            <li>
              <span className="fw-bold">Hong Kong</span> means the Hong Kong
              Special Administrative Region of the People's Republic of China;
            </li>
            <li>
              <span className="fw-bold">Liability</span> means any loss, damage,
              cost, charge, claim, demand, expense, judgment, action, proceeding
              or other liability whatsoever (including, without limitation, in
              respect of Taxes, Tax Rules, duties, levies, imposts and other
              charges) and including any value added tax or similar tax charged
              or chargeable in respect thereof and legal fees and expenses on a
              full indemnity basis;
            </li>
            <li>
              <span className="fw-bold">Platform</span> means a technology
              platform where Services are provided in respect of a FD121
              Account;
            </li>
            <li>
              <span className="fw-bold">Prohibited Transactions</span> has the
              meaning in Section 16;
            </li>
            <li>
              <span className="fw-bold">Restricted Activities</span> has the
              meaning in Section 16;
            </li>
            <li>
              <span className="fw-bold">Restricted Persons</span> means persons
              subject to comprehensive sanctions under economic sanctions
              programs administered in the jurisdictions where we conduct
              business, or residents, nationals or agents of Restricted
              Territories;
            </li>
            <li>
              <span className="fw-bold">Restricted Territories</span> means
              jurisdictions subject to comprehensive sanctions under economic
              sanctions programs administered in the jurisdictions where we
              conduct business;
            </li>
            <li>
              <span className="fw-bold">Rules</span> means the rules or
              regulations of any clearing system, the rules, operating
              procedures or market practice of any relevant stock exchange or
              market, any statute, law, regulation, ordinance, rule, judgment,
              order, decree, permit, concession, grant, franchise, license,
              agreement (whether concluded voluntarily or involuntarily),
              directive, requirement of, or other governmental restriction or
              any similar binding form of decision of or determination by or
              with, or any binding interpretation or administration of any of
              the foregoing by or with, any Authority, whether now or hereafter
              in effect;
            </li>
            <li>
              <span className="fw-bold">Services</span> means the services
              provided by FD121 in respect of the FD121 Account as set out in
              Section 3;
            </li>
            <li>
              <span className="fw-bold">Tax</span> means any tax, levy, impost,
              duty or other charge or withholding of a similar nature, and any
              penalty or interest payable in connection with any failure to pay
              or any delay in paying any of the same;
            </li>
            <li>
              <span className="fw-bold">Third Party Content</span> has the
              meaning in Section 27;
            </li>
            <li>
              <span className="fw-bold">Unauthorized FDD Transaction</span> has
              the meaning in Section 20.3; and
            </li>
            <li>
              <span className="fw-bold">User</span> means a user of Services
              provided by FD121 in respect of the FD121 Account.
            </li>
          </ol>
        </li>
        <li>
          <h2>
            Eligibility; Limitations; Registration Process; Identity
            Verification
          </h2>
          <h3>Eligibility; limitations</h3>
          <ol>
            <li>
              The FD121 Account and Services are currently only available to
              institutions located in supported jurisdictions as publicized by
              us from time to time.
            </li>
            <li>
              In registering to use the FD121 Account and Services on behalf of
              an entity, you represent and warrant that (i) such legal entity is
              duly organized and validly existing under the applicable Rules of
              the jurisdiction of its organization; (ii) you are duly authorized
              by such legal entity to act on its behalf, and (iii) such
              organization (and any affiliate entity) must not have been
              previously suspended or removed from the Services or any other
              service or product offered by FD121 or its affiliate entities.
            </li>
            <li>
              Use of certain Services may have further eligibility requirements
              that will need to be verified prior to you using such Services or
              from time to time in order to continue your use of the Services
              and may be subject to additional terms and conditions.
            </li>
            <li>
              By accessing or using the FD121 Account and Services, you further
              represent and warrant that:
              <ol type="a">
                <li>
                  you are not a Restricted Person, nor are you a resident of or
                  domiciled in a Restricted Territory.
                </li>
                <li>
                  you will not be using the FD121 Account and Services for any
                  illegal activity, including, but not limited to, illegal
                  gambling, money laundering, fraud, blackmail, extortion,
                  ransoming data, terrorism financing, other violent activities
                  or any prohibited market practices, including, but not limited
                  to, those listed under Section 16.
                </li>
              </ol>
            </li>
            <li>
              You also understand that there are additional representations and
              warranties made by you elsewhere in (or by reference in) this
              Agreement and that any misrepresentation by you is a violation of
              this Agreement.
            </li>
            <li>
              When you set up a FD121 Account, you will be required to designate
              an administrator for your account. FD121 may, in its discretion,
              grant access to your FD121 Account and some or all of the Services
              to other persons at your organisation (e.g. your employees) (such
              persons, <span className="fw-bold">Additional Users</span>). Such
              access is subject to FD121’s review and approval, and such
              Additional Users’ agreement to all of the terms hereof. To the
              extent that you choose to have Additional Users have access to the
              FD121 Account, you will have to designate those Additional Users
              and manage their access to your account. By you requesting such
              access, you and all Additional Users automatically agree to this
              Agreement.
            </li>
            <li>
              If FD121 determines that you or any of your Additional Users have
              violated this Agreement, including, but not limited to,
              transacting with Blocked Addresses or engaging in Restricted
              Activities or Prohibited Transactions then FD121 may be forced to
              terminate your FD121 Account.
            </li>
            <li>
              Notwithstanding the foregoing, FD121 may determine not to make the
              Services, in whole or in part, available in every market, either
              in its sole discretion or due to applicable Rules, depending on
              your location. We may also, without liability to you or any third
              party, refuse to let you register for a FD121 Account in FD121’s
              sole discretion.
            </li>
          </ol>
          <h3>Registration process; identity verification</h3>
          <ol>
            <li>
              When registering your FD121 Account, you must provide current,
              complete, and accurate information. You also agree to provide us,
              when registering a FD121 Account and on an ongoing basis, any
              additional information we request for the purposes of identity
              verification and the detection of money laundering, terrorist
              financing, fraud, or any other financial crime. You permit us and
              our affiliates to keep a record of such information and authorize
              us and our affiliates to make the inquiries, whether directly or
              through third parties, that we and our affiliates consider
              necessary or desirable to verify your identity or protect you
              and/or us against fraud or other financial crime, and to take
              action we reasonably deem necessary based on the results of such
              inquiries. When we carry out these inquiries, you understand,
              acknowledge and agree that your personal information may be
              disclosed to credit reference and fraud prevention or financial
              crime agencies and that these agencies may respond to our
              inquiries in full.
            </li>
            <li>
              In certain circumstances, we may require you to submit additional
              information about yourself or your business, provide records, and
              complete other verification steps.
            </li>
            <li>
              You represent and warrant that all information provided to us
              pursuant to this Agreement is true, accurate and not misleading in
              any respect. If any such information changes, it is your
              obligation to update such information as soon as possible.
            </li>
            <li>
              From time to time we and our affiliates may be required to request
              further information or review or update existing information
              regarding your account or your transactions to comply with
              applicable Rules, and in some cases, payment network or clearing
              system rules. Failure to provide such information, if requested by
              FD121, in a timely fashion may result in the suspension of your
              ability to use the Services (until you provide such information)
              or the closure of your FD121 Account.
            </li>
            <li>
              We reserve the right to maintain your account registration
              information after you terminate your FD121 Account for business
              and regulatory compliance purposes, subject to applicable Rules.
            </li>
          </ol>
        </li>
        <li>
          <h2>Services</h2>
          <ol>
            <li>
              FD121 provides FDD-related services, which are described more
              fully in the FDD Terms (the{" "}
              <span className="fw-bold">FDD Services</span>). To the extent that
              you utilize your FD121 Account for any FDD Services, the FDD Terms
              shall apply to all such Services. Notwithstanding anything to the
              contrary set forth in this Agreement or otherwise, in the event of
              a conflict between any term set forth herein and any term set
              forth in the FDD Terms in connection with any FDD Services, the
              terms of the FDD Terms shall prevail.
            </li>
            <li>
              The FD121 Account is not a bank account or a deposit account.
              FD121 does not offer custody services, wallet services, transfer
              services, payment processing services, treasury services, or
              trading services either in relation to FDD or fiat currency under
              this Agreement.
            </li>
          </ol>
        </li>
        <li>
          <h2>No Advice</h2>
          <p>
            FD121 does not provide investment, tax, or legal advice. You should
            consult your legal or tax professional regarding your specific
            situation. The information provided on our website or any
            third-party sites does not constitute investment advice, financial
            advice, trading advice, or any other sort of advice, and you should
            not treat any of the website's content as such.
          </p>
        </li>
        <li>
          <h2>Privacy</h2>
          <ol>
            <li>
              We are committed to protecting your personal information and
              helping you understand exactly how your personal information is
              being used. You should carefully read our Privacy Policy as it
              provides details on how your personal information is collected,
              stored, protected, processed, disclosed, and used.
            </li>
            <li>
              You confirm (a) on behalf of any individuals whose personal data
              you disclose to FD121 in connection with this Agreement that all
              personal data concerning any such individual (whether provided by
              you or any other person) may be used in accordance with our
              Privacy Policy; and (b) that you have obtained appropriate
              consents from such individuals to this effect.
            </li>
          </ol>
        </li>
        <li>
          <h2>Communications</h2>
          <ol>
            <li>
              By entering into this Agreement, you agree to receive electronic
              communications and notifications from us by way of e-mail, by
              posting a notice to our website, or through other electronic means
              as we shall reasonably select.
            </li>
            <li>
              This Agreement is provided to you and communicated in English. We
              will also communicate with you in English for all matters related
              to your use of the Services. Where we have provided you with a
              translation of the English language version of this Agreement or
              any information related to your FD121 Account, you acknowledge and
              agree that such translation is provided for your convenience only
              and that the English language version of the Agreement will govern
              your use of the Services.
            </li>
          </ol>
        </li>
        <li>
          <h2>Security of Your Information</h2>
          <ol>
            <li>
              You are responsible for maintaining the confidentiality and
              security of all account names, User IDs, passwords, personal
              identification numbers (PINs) and other access codes that you use
              to access the Services. You are responsible for keeping your email
              address and all other account holder and User information up to
              date in your FD121 Account profile and for maintaining the
              confidentiality of your User information. You agree to notify
              FD121 immediately if you become aware of any unauthorized use of
              your FD121 Account, the Platform, the Services, or any other
              breach of security regarding any of the aforementioned. We
              strongly advise you to enable all security features that are
              available to you (such as, by way of example two-factor
              authentication); this offers you enhanced protection from possible
              malicious attacks. FD121 will not be liable for any loss or damage
              arising from your failure to protect your account information.
            </li>
            <li>
              We shall not bear any liability for any damage or interruptions
              caused by any computer viruses, spyware, or other malware that may
              affect your computer or other equipment, or any phishing,
              spoofing, or other attack. We recommend the regular use of a
              reliable virus and malware screening and prevention software. If
              you question the authenticity of a communication purporting to be
              from FD121, you should login to your FD121 Account directly
              through the Services website or related mobile application, if
              any, and not by clicking links contained in emails.
            </li>
          </ol>
        </li>
        <li>
          <h2>Account Suspension &amp; Closure</h2>
          <ol>
            <li>
              We may, without liability to you or any third party, suspend your
              FD121 Account, consolidate your FD121 Accounts if you have more
              than one, or terminate your FD121 Account or suspend your use of
              one or more of the Services subject to the terms of this
              Agreement, as determined in our sole and absolute discretion. Such
              actions may be taken as a result of account inactivity, failure to
              respond to customer support requests, failure to positively
              identify you, where there is a failure to comply with applicable
              Rules, your violation of the terms of this Agreement, or for other
              similar reasons. FD121 may also temporarily suspend access to your
              FD121 Account in the event that a technical problem causes system
              outage or FD121 Account errors until the problem is resolved. For
              the avoidance of doubt, in the event your FD121 Account is
              suspended or closed, you will no longer be able to access any of
              the Services.
            </li>
            <li>
              You may terminate this Agreement at any time by closing your FD121
              Account in accordance with this Agreement. You may not terminate
              your FD121 Account if FD121 believes, in its sole discretion, that
              such closure is being performed in an effort to evade Rules, a
              court order or legal or regulatory investigation or to avoid
              paying any amounts otherwise due to FD121.
            </li>
            <li>
              We encourage you to redeem your FDD prior to issuing a request to
              terminate FD121 Account. We reserve the right to restrict or
              refuse to permit redemptions of FDD if (i) your FD121 Account has
              otherwise been suspended or unilaterally terminated by FD121 in
              accordance with this Agreement or (ii) to do so would be
              prohibited by law or a court order or we have determined that any
              of the related FDD were obtained fraudulently.
            </li>
            <li>
              Upon closure or suspension of your FD121 Account, you authorize
              FD121 to cancel or suspend pending transactions and forfeit all
              proprietary rights and claims against FD121 in relation to any
              fiat currency otherwise eligible for redemption.
            </li>
            <li>
              In the event that you or FD121 terminates this Agreement or your
              access to the Services, or cancels your FD121 Account, you remain
              liable for all activity conducted on or with your FD121 Account
              while it was active and for all amounts due hereunder.
            </li>
          </ol>
        </li>
        <li>
          <h2>Fees</h2>
          <ol>
            <li>
              FD121 may charge fees in connection with the Services. You agree
              to pay the fees shown to you, if any, or as separately agreed
              between you and FD121 without set off or deduction. Fees are
              generally disclosed fee schedule which we publicize from time to
              time; we may change any of the fees that FD121 charges at any
              time, with or without notice.
            </li>
            <li>
              You are responsible for, and agree to pay, all fees that may be
              charged by your financial institution or wallet service provider
              in connection with sending funds to FD121 or receiving funds from
              FD121. FD121 is not responsible for any charges that you incur
              based on delayed processing of deposits or withdrawals that might
              result from overdraft fees or otherwise. Failure to separately
              account for the fees that may be charged by your financial
              institution or wallet service provider in connection with sending
              funds to FD121 or receiving funds from FD121 may result in those
              fees being deducted from the funds that you send to or receive
              from FD121, or insufficient funds to complete a transaction.
            </li>
          </ol>
        </li>
        <li>
          <h2>
            Forks, Advanced Protocols, Other Digital Currencies &amp;
            Supplemental Protocols Not Supported
          </h2>
          <h3>Forks</h3>
          <ol>
            <li>
              As a result of the decentralized and open-source nature of
              blockchain it is possible that sudden, unexpected or controversial
              changes (forks) can be made to any blockchain that can change the
              usability, functions, natyure, value or even name of a given
              blockchain and smart contracts deployed on it. It is also possible
              that such forks result in multiple versions of a smart contracts,
              each with its own value, and therefore may reduce the value of the
              original “unforked” version of a digital currency.
            </li>
            <li>
              FD121 is under no obligation to support a fork of a FDD that you
              hold, whether or not such forked FDD holds value at or following
              such fork. If FD121 elects, in its sole discretion, to support a
              fork of a FDD it will make an electronic communication or
              notification by way of e-mail, by posting a notice to our website,
              or through other electronic means, and shall bear no liability for
              any real or potential losses that may result based on the decision
              to support such fork or the timing of implementation of support.
              If FD121, in its sole discretion, does not elect to support a fork
              of a given FDD, FD121 assumes no responsibility or liability
              whatsoever for any losses or other issues that might arise from an
              unsupported fork of a FDD.
            </li>
            <li>
              Unless otherwise specified in this Agreement, FD121 Accounts also
              do not support any other digital currencies, tokens, coins or
              forked protocols whether or not they are created as a result of a
              fork of a FDD, are independently created or otherwise, and
              regardless of whether or not such other digital currencies hold
              any value. Note that in the event of a fork of a FDD, FD121 may be
              forced to suspend all activities relating to such FDD (including
              both buying and selling) for an extended period of time until
              FD121 has determined in its sole discretion that such
              functionality can be restored (
              <span className="fw-bold">Downtime</span>). This Downtime will
              likely occur immediately upon a “fork” of a given FDD, potentially
              with little to no warning, and during this period of Downtime you
              will not be able to buy or sell the digital currency subject to
              such fork.
            </li>
          </ol>
        </li>
        <li>
          <h2>No Deposit Protection</h2>
          <p>
            No fiat currency is held in your FD121 Account. Any fiat currency
            transferred to FD121 is not subject to deposit protection,
            including, but not limited to, the Deposit Protection Scheme in Hong
            Kong. Additionally, no interest will accrue or be paid to you, on
            fiat currency (if any) held in your FD121 Account. See section 3 of
            the FDD Terms for more details.
          </p>
        </li>
        <li>
          <h2>Right to Change/Remove Features or Suspend/Delay Transactions</h2>
          <p>
            Subject to the FDD Terms, we reserve the right to change, suspend,
            or discontinue any aspect of the Services or the Platform at any
            time, including hours of operation or availability of any feature,
            without notice and without liability. We may, in our sole
            discretion, delay, suspend or cancel any transaction if we believe
            that such transaction is suspicious, may involve fraud or
            misconduct, violates applicable Rules or payment network or clearing
            house rules, or violates any term of this Agreement.
          </p>
        </li>
        <li>
          <h2>Insufficient Funds</h2>
          <p>
            If do not provide us with sufficient funds (fiat currency or FDD) to
            complete a transaction, such transaction will not be completed.
          </p>
        </li>
        <li>
          <h2>Refunds; Reversals</h2>
          <p>
            Once a transaction has been initiated, it cannot be reversed or
            refunded, except as set forth in this Agreement. You should
            periodically review statements from your financial institution or
            wallet service provider and any other service that you use to
            transact FDD. You can also access the record of transactions in your
            FD121 Account by logging into your FD121 Account.
          </p>
        </li>
        <li>
          <h2>Blocked Addresses &amp; Forfeited Assets</h2>
          <p>
            FD121 reserves the right to “block” certain digital currency
            addresses that it determines, in its sole discretion, are associated
            with illegal activity or activity that otherwise violates the terms
            of this Agreement (
            <span className="fw-bold">Blocked Addresses</span>). In the event
            that you send digital currency to a Blocked Address, or receive
            digital currency from a Blocked Address, FD121 may freeze such
            digital currency and take steps to terminate your FD121 Account. In
            certain circumstances, FD121 may deem it necessary to report such
            suspected illegal activity to applicable law enforcement agencies
            and you may forfeit any rights associated with your FDD. FD121 may
            also be forced to freeze and potentially surrender FDD in the event
            it receives a legal order from a government authority requiring it
            to do so.
          </p>
        </li>
        <li>
          <h2>Restricted Activities and Prohibited Transactions</h2>
          <ol>
            <li>
              In connection with your use of the Services, you hereby agree that
              you will not:
              <ol type="a">
                <li>
                  Send FDD to individuals and institutions located in
                  jurisdictions other than in supported jurisdictions;
                </li>
                <li>
                  Violate (or assist any other party in violating) any
                  applicable Rules;
                </li>
                <li>
                  Intentionally try to defraud (or assist in the defrauding of)
                  FD121 or other FD121 Account Users;
                </li>
                <li>Provide false, inaccurate, or misleading information;</li>
                <li>
                  Take any action that interferes with, intercepts, or
                  expropriates any system, data, or information;
                </li>
                <li>
                  Partake in any transaction involving the proceeds of illegal
                  activity;
                </li>
                <li>
                  Transmit or upload any virus, worm, or other malicious
                  software or program;
                </li>
                <li>
                  Attempt to gain unauthorized access to other FD121 Accounts,
                  the FD121 website, or any related networks or systems;
                </li>
                <li>
                  Use the Services on behalf of any third party or otherwise act
                  as an intermediary between FD121 and any third parties;
                </li>
                <li>
                  Collect any User information from other FD121 Account Users,
                  including, without limitation, email addresses;
                </li>
                <li>
                  Defame, harass, or violate the privacy or intellectual
                  property rights of FD121 or any other FD121 Account Users; or
                </li>
                <li>
                  Upload, display or transmit any messages, photos, videos or
                  other media that contain illegal goods, violent, obscene or
                  copyrighted images or materials (such activities,{" "}
                  <span className="fw-bold">Restricted Activities</span>).
                </li>
              </ol>
            </li>
            <li>
              16.2 In addition, using the Services for transactions related to
              the following is prohibited, and FD121 reserves the right to
              monitor use of the Platform, including for but not limited to,
              transactions that relate to:
              <ol type="a">
                <li>
                  Any Restricted Persons or persons or entities located in
                  Restricted Territories (as each term is defined in Section
                  29);
                </li>
                <li>
                  Weapons of any kind, including but not limited to firearms,
                  ammunition, knives, explosives, or related accessories;
                </li>
                <li>
                  Controlled substances, including but not limited to narcotics,
                  prescription drugs, steroids, or related paraphernalia or
                  accessories, unless possession of and transactions involving
                  such controlled substances are authorized by the jurisdiction
                  in which the User is based as well as by the jurisdiction in
                  which the transaction takes place, and provided any such
                  transactions comply with all applicable Rules;
                </li>
                <li>
                  Gambling activities including but not limited to sports
                  betting, casino games, horse racing, dog racing, games that
                  may be classified as gambling, or other activities that
                  facilitate any of the foregoing, unless such activities are
                  authorized by the jurisdiction in which the User is based as
                  well as by the jurisdiction in which the transaction takes
                  place, and provided any such activities comply with all
                  applicable Rules;
                </li>
                <li>Money laundering or terrorist financing;</li>
                <li>
                  Any sort of Ponzi scheme, pyramid scheme, or multi-level
                  marketing program;
                </li>
                <li>
                  Goods or services that infringe or violate any copyright,
                  trademark, or proprietary rights under the Rules of any
                  jurisdiction;
                </li>
                <li>
                  Credit repair services, or other services that may present
                  consumer protection risks;
                </li>
                <li>
                  Court ordered payments, structured settlements, tax payments,
                  or tax settlements;
                </li>
                <li>Any unlicensed money transmitter activity;</li>
                <li>Layaway systems, or annuities;</li>
                <li>
                  Counterfeit goods, including but not limited to fake or
                  “novelty” IDs;
                </li>
                <li>
                  Wash trading, front-running, insider trading, market
                  manipulation or other forms of market-based fraud or deceit;
                </li>
                <li>
                  Purchasing goods of any type from{" "}
                  <span className="fw-bold">Darknet</span> markets, or any other
                  service or website that acts as a marketplace for illegal
                  goods (even though such marketplace might also sell legal
                  goods); or
                </li>
                <li>
                  Any other matters, goods, or services that from time to time
                  we communicate to you that are unacceptable and which, for
                  example, may be restricted by our and your financial
                  institution, wallet service provider, or payment partners
                  (such (a) – (o) transactions,{" "}
                  <span className="fw-bold">Prohibited Transactions</span>).
                </li>
              </ol>
            </li>
            <li>
              In the event that FD121 becomes aware or suspects you are making
              or attempting any Restricted Activities or Prohibited
              Transactions, FD121 will consider it to be a violation of this
              Agreement and may suspend or terminate your FD121 Account.
            </li>
          </ol>
        </li>
        <li>
          <h2>Taxes</h2>
          <p>
            FD121 will maintain a record of your transaction history, which you
            will be able to access through your FD121 Account for purposes of
            making any required Tax filings or payments, but it is your
            responsibility to determine what, if any, Taxes apply to the
            payments you make or receive, and to collect, report, and remit the
            correct tax to the appropriate tax authority. FD121 will make any
            Tax withholdings or filings that we are required by law to make, but
            FD121 is not responsible for determining whether Taxes apply to your
            transactions, or for collecting, reporting, or remitting any taxes
            arising from any transaction.
          </p>
        </li>
        <li>
          <h2>Indemnification; Release</h2>
          <ol>
            <li>
              You agree to indemnify and hold FD121, its affiliates, and service
              providers, and each of their officers, directors, agents, joint
              venturers, employees, and representatives harmless from any claim
              or demand (including attorneys’ fees and any Liabilities, fines,
              fees or penalties imposed by any regulatory authority) arising out
              of your breach of this Agreement, your violation of any Rules or
              your use of the Services.
            </li>
            <li>
              If you have a Dispute with one or more Users or third parties, you
              release FD121 (and its affiliates and service providers, and each
              of their officers, directors, agents, joint ventures, employees
              and representatives) from all claims, demands, and damages (actual
              and consequential) of every kind and nature arising out of or in
              any way connected with such Disputes.
            </li>
          </ol>
        </li>
        <li>
          <h2>Limitation of Liability; No Warranty</h2>
          <ol>
            <li>
              You expressly understand and agree that FD121 and our affiliates
              and service providers (including, for the avoidance of doubt, any
              custodian), and their respective officers, directors, agents,
              joint venturers, employees, and representatives will, to the
              extent permitted by Rules, not be liable for any indirect,
              incidental, special, consequential, exemplary damages, or damages
              for loss of profits including but not limited to, damages for loss
              of goodwill, use, data, or other intangible losses (even if FD121
              has been advised of the possibility of such damages), whether
              based on contract, tort, negligence, strict liability, or
              otherwise, resulting from: (i) the use or the inability to use the
              Services; (ii) the cost of procurement of substitute goods and
              services resulting from any goods, data, information, or services
              purchased or obtained or messages received or transactions entered
              into through or from the Services; (iii) unauthorized access to or
              alteration of your transmissions or data; or (iv) any other matter
              relating to the Services.
            </li>
            <li>
              The Services are provided "as is" and without any representation
              or warranty, whether express, implied or statutory. FD121, our
              affiliates, and our respective officers, directors, agents, joint
              venturers, employees, and suppliers specifically disclaim any
              implied warranties of title, merchantability, fitness for a
              particular purpose or non-infringement. FD121 makes no warranty
              that (i) the Services will meet your requirements, (ii) the
              Services will be uninterrupted, timely, secure, or error-free, or
              (iii) the quality of any products, services, information, or other
              material purchased or obtained by you will meet your expectations.
            </li>
            <li>
              FD121 will make reasonable efforts to ensure that requests for
              FD121 Account transactions are processed in a timely manner, but
              FD121 makes no representations or warranties regarding the amount
              of time needed to complete processing because the Services are
              dependent upon many factors outside of our control, such as delays
              in the banking system, electronic communication systems or mail
              service.
            </li>
          </ol>
        </li>
        <li>
          <h2>Unauthorized and Incorrect Transactions</h2>
          <ol>
            <li>
              When any transaction occurs using your credentials, we will assume
              that you authorized such transaction, unless you notify us
              otherwise. If you believe you did not authorize a particular
              transaction or that a transaction was incorrectly carried out, you
              must contact us as soon as possible by email. It is important that
              you regularly check your FD121 Account balances and your
              transaction history regularly to ensure you notify us as soon as
              possible of any unauthorized or incorrect transactions. We are not
              responsible for any claim for unauthorized or incorrect
              transactions unless you have notified us in accordance with this
              Section.
            </li>
          </ol>
          <h3>Unauthorized and incorrect FDD transactions</h3>
          <ol>
            <li>
              If an Unauthorized FDD Transaction or Error occurs through your
              FD121 Account, and you follow the procedures set forth in this
              Section, FD121 will reimburse you for the amount of any eligible
              Unauthorized FDD Transaction and any losses resulting from an
              Error, subject to the limitations set forth in this Section.
            </li>
          </ol>
          <h3>Unauthorized FDD transaction or error</h3>
          <ol>
            <li>
              For purposes of this Section,{" "}
              <span className="fw-bold">Unauthorized FDD Transaction</span>{" "}
              means any transaction that was initiated from your FD121 Account
              without your authorization and that does not benefit you.
            </li>
            <li>
              For purposes of this Section, an{" "}
              <span className="fw-bold">Error</span> means one of the following:
              (i) if your bank account is charged for an issuance of FDD, FD121
              or its custodian receives the funds, but the corresponding FDD is
              not reflected in your FD121 Account or does not show up in your
              account with your wallet service provider, (ii) if you complete a
              redemption and the funds are not properly delivered by FD121 to
              your bank account, (iii) if the transaction records in your FD121
              Account do not accurately reflect your transactions, or (iv) there
              is some other material mathematical or computational error by
              FD121 in calculating subscription or redemption amounts.
            </li>
          </ol>
          <h3>Notifying FD121 of an unauthorized FDD transaction or error</h3>
          <ol>
            <li>
              You should notify us immediately if you believe that (i) an
              Unauthorized FDD Transaction or Error has occurred, (ii) your
              password and/or two-factor authentication access has been
              compromised, (iii) a device on which contains an active FD121
              session has been stolen or lost, or (iv) you believe there is an
              Error in your transaction records or account history, or you have
              a question regarding your transaction records or account history.
            </li>
            <li>
              In order for Unauthorized FDD Transactions or Errors to be covered
              by FD121, you must notify us within seven (7) days of such
              Unauthorized FDD Transaction or Error showing up in your
              transaction history and/or account statement. If you do not notify
              us within seven (7) days, then you will not be covered by the
              protections of this Section.
            </li>
          </ol>
          <h3>Notification process</h3>
          <ol>
            <li>
              You should notify us of an Unauthorized FDD Transaction or Error
              by contacting us.
            </li>
            <li>
              However you choose to notify us, you must, and it is your
              responsibility to include:
              <ol type="a">
                <li>the name and email associated with your FD121 Account</li>
                <li>
                  a detailed description of what Unauthorized FDD Transaction or
                  Error you believe occurred, and
                </li>
                <li>
                  evidence substantiating the Unauthorized FDD Transaction or
                  Error you believe occurred, and
                </li>
                <li>
                  the amount of such Unauthorized FDD Transaction or Error.
                </li>
              </ol>
            </li>
            <li>
              Note that in connection with investigating and/or resolving any
              Unauthorized FDD Transaction or Error, we may need to request
              further information from you regarding the circumstances of the
              Unauthorized FDD Transaction or Error. If you fail to provide any
              information that we request, it will inhibit our ability to
              resolve your issue and limit your rights under this Section.
            </li>
          </ol>
          <h3>Steps FD121 will take following notification</h3>
          <ol>
            <li>
              Once you have provided us with proper notification (including
              delivering all of the information set forth in the subsection
              above and responding to any requests for further information), we
              will investigate the potential Unauthorized FDD Transaction or
              Error. We will typically be able to complete our investigation
              within ten (10) days, but may require up to thirty (30) days if
              your FD121 Account is new (meaning your FD121 Account has been
              active for less than thirty (30) days at the time we receive your
              notification).
            </li>
            <li>
              Once we have completed our investigation, we will notify you of
              our decision within three (3) business days, and regardless of the
              results of our investigation, we will send you an email explaining
              the basis for our decision. Our decisions are final and not
              subject to further review.
            </li>
          </ol>
        </li>
        <li>
          <h2>Right to Set-off</h2>
          <p>
            FD121 may and is authorized, without prior notice and both before
            and after demand, to set off the whole or any part of your
            liabilities or other amounts payable to FD121, including but not
            limited to fees, whether such amounts are present or future, actual
            or contingent, or liquidated or unliquidated, against any sums held
            by FD121 and owed to you, whether under this Agreement or any other
            agreement between FD121 and you.
          </p>
        </li>
        <li>
          <h2>Amendments</h2>
          <ol>
            <li>
              FD121 may amend any portion of this Agreement at any time. The
              revised version of this Agreement with an updated revision date
              will be available to you by ways set out in Section 6.1 above. The
              changes will become effective, and shall be deemed accepted by
              you, the first time you use the Services after the initial posting
              of the revised Agreement and shall apply on a going-forward basis
              with respect to transactions initiated after the posting date. In
              the event that you do not agree with any such modification, your
              sole and exclusive remedy is to terminate your use of the Services
              and close your FD121 Account. You agree that we shall not be
              liable to you or any third party as a result of any losses
              suffered by any modification or amendment of this Agreement.
            </li>
            <li>
              If the revised Agreement includes a material change, we will
              provide you with prior notice via our website and/or email before
              the material change becomes effective. For this purpose a{" "}
              <span className="fw-bold">material change</span> means a
              significant change other than changes that (i) are to your
              benefit, (ii) are required to be made to comply with applicable
              Rules or as otherwise required by one of our regulators, (iii)
              relates to a new product or service made available to you, or (iv)
              to otherwise clarify an existing term.
            </li>
          </ol>
        </li>
        <li>
          <h2>Assignment</h2>
          <p>
            You may not transfer or assign this Agreement or any rights or
            obligations hereunder, by operation of law or otherwise and any such
            attempted assignment shall be void. We reserve the right to freely
            assign this Agreement and the rights and obligations of this
            Agreement to any third party at any time without notice or consent.
            If you object to such transfer or assignment, you may stop using our
            Services and terminate this Agreement by contacting FD121’s support
            team and asking us to close your FD121 Account.
          </p>
        </li>
        <li>
          <h2>Change of Control</h2>
          <p>
            In the event that FD121 is acquired by or merged with a third party
            entity, we reserve the right, in any of these circumstances, to
            transfer or assign the information we have collected from you as
            part of such merger, acquisition, sale, or other change of control.
          </p>
        </li>
        <li>
          <h2>Survival; Force Majeure</h2>
          <ol>
            <li>
              Upon termination of your FD121 Account or this Agreement for any
              reason, all rights and obligations of the parties that by their
              nature are continuing will survive such termination.
            </li>
            <li>
              FD121 shall have no liability for any failure or delay resulting
              from any condition beyond our reasonable control, including acts
              of God, terrorism, pandemics, shortage of supply, labour
              difficulties (including strikes), war, civil unrest, fire, floods,
              electrical outages, equipment or transmission failures, internet
              interruptions, vendor failures (including information technology
              providers), or other similar causes.
            </li>
          </ol>
        </li>
        <li>
          <h2>Third-party Applications</h2>
          <p>
            If you grant express permission to a third party to connect to your
            FD121 Account, either through the third party's product or through
            FD121, you acknowledge that granting permission to a third party to
            take specific actions on your behalf does not relieve you of any of
            your responsibilities under this Agreement. Further, you acknowledge
            and agree that you will not hold FD121 responsible for, and will
            indemnify FD121 from, any liability arising from the actions or
            inactions of this third party in connection with the permissions you
            grant.
          </p>
        </li>
        <li>
          <h2>Website; Third-party Content</h2>
          <p>
            FD121 strives to provide accurate and reliable information and
            content on the FD121 website, but such information may not always be
            correct, complete, or up to date. FD121 will update the information
            on the FD121 website as necessary to provide you with the most up to
            date information, but you should always independently verify such
            information. The FD121 website may also contain links to third party
            websites, applications, events or other materials (
            <span className="fw-bold">Third Party Content</span>). Such
            information is provided for your convenience and links or references
            to Third Party Content do not constitute an endorsement by FD121 of
            any products or services. FD121 shall have no liability for any
            losses incurred as a result of actions taken in reliance on the
            information contained on the FD121 website or in any Third Party
            Content.
          </p>
        </li>
        <li>
          <h2>Limited License; IP Rights</h2>
          <p>
            We grant you a limited, non-exclusive, non-sublicensable, and
            non-transferable license, subject to the terms and conditions of
            this Agreement, to access and use the Services solely for approved
            purposes as determined by FD121. Any other use of the Services or
            the Platform is expressly prohibited. FD121 and its licensors
            reserve all rights in the Services and you agree that this Agreement
            does not grant you any rights in or licenses to the Services except
            for the limited license set forth above. Except as expressly
            authorized by FD121, you agree not to modify, reverse engineer,
            copy, frame, scrape, rent, lease, loan, sell, distribute, or create
            derivative works based on the Services or the Platform, in whole or
            in part. If you violate any portion of this Agreement, your
            permission to access and use the Services and your FD121 Account may
            be terminated pursuant to this Agreement. All logos related to the
            Services are either trademarks, or registered marks of FD121 or its
            licensors. You may not copy, imitate, or use them without FD121's
            prior written consent. All right, title, and interest in and to the
            FD121 website, any content thereon, the Services, and all technology
            and any content created or derived from any of the foregoing is the
            exclusive property of FD121 and its licensors.
          </p>
        </li>
        <li>
          <h2>Applicable Rules; Legal Compliance</h2>
          <ol>
            <li>
              Your use of the Services is subject to all applicable Rules,
              including, without limitation, all applicable tax, anti-money
              laundering (<span className="fw-bold">AML</span>) and
              counter-terrorist financing (<span className="fw-bold">CTF</span>)
              provisions.
            </li>
            <li>
              You unequivocally agree and understand that by registering a FD121
              Account and using the Services in any capacity, you will act in
              compliance with and be legally bound by this Agreement and all
              applicable Rules (including, without limitation, those stated in
              this Section, where applicable). For the avoidance of doubt,
              continued use of your FD121 Account and FD121’s obligations to you
              under this Agreement are conditional on your continued compliance
              at all times with this Agreement and all applicable Rules. FD121’s
              AML and CTF procedures are guided by all applicable Rules
              regarding AML and CTF. These standards are designed to prevent the
              use of the Services for money laundering or terrorist financing
              activities. We take compliance very seriously and it is our policy
              to take all necessary steps to prohibit fraudulent transactions,
              report suspicious activities, and actively engage in the
              prevention of money laundering and any related acts that
              facilitate money laundering, terrorist financing or any other
              financial crimes.
            </li>
            <li>
              You agree, represent, and warrant that all funds used or applied
              for the Services and transactions in relation to your FD121
              Account in the future, are not the direct or indirect proceeds of
              any criminal or fraudulent activity.
            </li>
            <li>
              The Services are subject to economic sanctions programs
              administered in the jurisdictions where we conduct business,
              pursuant to which we are prohibited from providing services or
              entering into relationships with certain individuals and
              institutions. By using the Services, you represent that your
              actions are not in violation of such sanctions programs.
            </li>
            <li>
              In the event that we are required to block funds associated with
              your use of Services in accordance with a sanctions program, or
              other similar government sanctions programs, we may: (i) suspend
              your FD121 Account; (ii) terminate your FD121 Account; (iii)
              return funds to the destination of their origin or to an account
              specified by authorities, in accordance with Section 15. In
              certain cases, taking one or more of these actions may result in a
              forfeiture of some or all of your assets held with FD121. We are
              not responsible for any losses, whether direct or indirect, that
              you may incur as a result of our complying with applicable Rules,
              the guidance or direction of any regulatory authority or
              government agency, or any writ of attachment, lien, levy,
              subpoena, warrant, or other legal order.
            </li>
          </ol>
        </li>
        <li>
          <h2>Governing Law; Jurisdiction</h2>
          <p>
            The Agreement is governed by the laws of Hong Kong. The courts of
            Hong Kong have exclusive jurisdiction to settle any Dispute and each
            party to this Agreement irrevocably submits to the exclusive
            jurisdiction of the courts of Hong Kong. The parties agree that the
            courts of Hong Kong are the most appropriate and convenient courts
            to settle disputes and accordingly no party will argue to the
            contrary.
          </p>
        </li>
        <li>
          <h2>Miscellaneous</h2>
          <ol>
            <li>
              The failure of FD121 to exercise or enforce any right or provision
              of the Agreement shall not constitute a waiver of such right or
              provision.
            </li>
            <li>
              If any provision of this Agreement shall be adjudged by any court
              of competent jurisdiction to be unenforceable or invalid, that
              provision shall be limited or eliminated to the minimum extent
              necessary so that this Agreement shall otherwise remain in full
              force and effect and remain enforceable between the parties,
              except as specified in Section 22.
            </li>
            <li>
              The headings and explanatory text are for reference purposes only
              and in no way define, limit, construe, or describe the scope or
              extent of such section.
            </li>
            <li>
              This Agreement, including any additional agreement incorporated by
              reference herein; FD121’s policies governing the Services
              referenced herein (including, without limitation, those set forth
              in the FDD Terms); and our Privacy Policy constitute the entire
              agreement between you and FD121 with respect to the use of the
              Services.
            </li>
          </ol>
        </li>
        <li>
          <h2>Third-party Rights</h2>
          <p>
            A person who is not a party to this Agreement shall have no rights
            under the Contracts (Rights of Third Parties) Ordinance (Cap. 623 of
            the Laws of Hong Kong) to enforce or enjoy the benefit of any
            provision of this Agreement.
          </p>
        </li>
      </ol>
    </div>
  </section>
</div>
  )
}

export default UserAgreement;