
const RiskFactors = () => {
  return(
    <div className="offcanvas-wrap">
  <section className="py-15 py-xl-20 pb-xl-15 bg-light">
    <div className="container mt-10">
      <h1>FDD Risk Factors</h1>
      <p className="lead mt-3">
        The following list of risks associated with FDD and the FDD Services are
        not exhaustive.
      </p>
    </div>
  </section>
  <section className="py-15 py-xl-20 legal-content">
    <div className="container">
      <h2>No guarantee of price stability</h2>
      <p>
        FD121 does not guarantee that the value of one will always equal one
        dollar (or its equivalent) of the fiat currency which the FDD is
        denominated in (Dollar) across all platforms. Due to a variety of
        factors outside of FD121’s control, the value of FDD can fluctuate above
        or below one Dollar.
      </p>
      <p>
        FD121 cannot control how third parties value FDD, and FD121 is not
        responsible for any losses or other issues that may result from
        fluctuations in the value of FDD. The risk of loss in holding or trading
        FDD may be substantial and losses may occur over a short period of time.
      </p>
      <p>
        The value of FDD may be derived from or influenced by the continued
        willingness of market participants to subscribe for FDD in exchange of
        fiat currencies, which may result in the potential for permanent and
        total loss of value of FDD should the market for FDD disappear.
      </p>
      <p>
        You should also take into account the potential risk of loss in respect
        of buying and selling FDD for the fiat currency which the FDD is
        denominated in, arising from fluctuations in currency exchange rates.
        Currency exchange rates can be very volatile and can change quickly and
        unpredictably.
      </p>
      <h2>Third-party platforms</h2>
      <p>
        FDD is based on open source software and blockchain. This means that
        third parties can elect to support FDD on their platforms without any
        authorization or approval by FD121 or anyone else. As a result, FDD
        support on any third-party platform (including digital assets exchange
        platforms) does not imply any endorsement by FD121 that such third-party
        services are valid, legal, stable, or otherwise appropriate. We do not
        own or control the blockchain that FDD runs on and are not responsible
        for the operation of the blockchain network and make no guarantees
        regarding the network’s security, functionality, or availability. FD121
        is not responsible for any losses or other issues you might encounter
        using FDD on non-FD121 platforms. Users accept all consequences from the
        holding and transfer of FDD. FDD transactions are not reversible. Once
        you send FDD to an address, you accept the risk that you may lose access
        to, and any claim on, that FDD indefinitely or permanently. For example,
        (i) an address may have been entered incorrectly and the true owner of
        the address may never be discovered, (ii) you may not have (or
        subsequently lose) the private key associated with such address, (iii)
        an address may belong to an entity that will not return the FDD, (iv) an
        address belongs to an entity that may return the FDD but first requires
        action on your part, such as verification of your identity.
      </p>
      <h2>Software protocols and operational challenges</h2>
      <p>
        You are aware of and accept the risk of operational challenges. FD121
        may experience sophisticated cyber-attacks, unexpected surges in
        activity, or other operational or technical difficulties that may cause
        interruptions to the FDD Services. You understand that the FDD Services
        may experience operational issues that lead to delays, including delays
        in redeeming FDD. You agree to accept the risk of transaction failure
        resulting from unanticipated or heightened technical difficulties,
        including those resulting from sophisticated attacks. You agree not to
        hold FD121 accountable for any related losses.
      </p>
      <h2>Compliance</h2>
      <p>
        You are responsible for complying with applicable law. You agree that
        FD121 is not responsible for determining whether or which laws may apply
        to your transactions, including tax laws. You are solely responsible for
        reporting and paying any taxes arising from your use of the FDD
        Services, including any accurate reporting of the tax or legal status of
        FDD in your jurisdiction.
      </p>
      <h2>Legislative and regulatory changes</h2>
      <p>
        Legislative and regulatory changes or actions in the jurisdictions where
        we conduct business may adversely affect the tokenization of fiat
        currency into FDD, and the use, transfer, redemption, and/or value of
        FDD.
      </p>
      <h2>Legal treatment of FDD transfers</h2>
      <p>
        The regulatory status of FDD and blockchain technology is unclear or
        unsettled in many jurisdictions. It is difficult to predict how or
        whether regulatory agencies may apply existing regulation with respect
        to FDD, blockchain technology, and its applications. Accordingly, it is
        not possible to determine whether an FDD transfer would be recognized
        under applicable law by a court or regulator.
      </p>
      <h2>On-chain transactions irreversible</h2>
      <p>
        When FDD is sent out of your FDD Account to a third-party FDD address,
        such transaction is completed on the blockchain. This means that such
        transaction is irreversible and FD121 does not have the ability to
        reverse or recall any transaction once initiated. You bear all
        responsibility for any losses that might be incurred as a result of
        sending FDD to an incorrect or unintended FDD address.
      </p>
    </div>
  </section>
</div>
  )
}

export default RiskFactors;