import { Link } from "react-router-dom";

const Legal = () => {
  return (
    <div className="offcanvas-wrap">
  <section className="py-15 py-xl-20 pb-xl-15 bg-light">
    <div className="container mt-10">
      <h1>Legal &amp; Compliance</h1>
    </div>
  </section>
  <section className="py-15 py-xl-20">
    <div className="container">
      <h5>Policies</h5>
      <hr className="my-5" />
      <div className="row">
        <div className="col-sm-4">
          <Link
            to="/legal/policies/privacy-policy"
            className="card bg-light card-hover-border rounded mb-3"
          >
            <div className="card-body">
              <span className="h6">Privacy Policy</span>
              <div className="card-text">
                <small className="text-muted">
                  Current Version: 2023-05-20
                </small>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <h5>Terms &amp; Conditions</h5>
      <hr className="my-5" />
      <div className="row">
        <div className="col-sm-4">
          <Link
            to="/legal/terms-and-conditions/fdd-terms"
            className="card bg-light card-hover-border rounded mb-3"
          >
            <div className="card-body">
              <span className="h6">FDD Terms</span>
              <div className="card-text">
                <small className="text-muted">
                  Current Version: 2023-05-26
                </small>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-sm-4">
          <Link
            to="/legal/terms-and-conditions/fdd-risk-factors"
            className="card bg-light card-hover-border rounded mb-3"
          >
            <div className="card-body">
              <span className="h6">FDD Risk Factors</span>
              <div className="card-text">
                <small className="text-muted">
                  Current Version: 2023-05-26
                </small>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-sm-4">
          <Link
            to="/legal/terms-and-conditions/fd121-account-user-agreement"
            className="card bg-light card-hover-border rounded mb-3"
          >
            <div className="card-body">
              <span className="h6">FD121 Account User Agreement</span>
              <div className="card-text">
                <small className="text-muted">
                  Current Version: 2023-05-26
                </small>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </section>
</div>
  )
}

export default Legal;