import React, { useState, useRef } from "react";
import { useEffect } from "react";

const CountLeft = ({ lastClaimed }) => {
  const [timeLeft, setTimeLeft] = useState(`0 days : 0 hours : 0 minutes : 0 seconds`);
  const Ref = useRef(null);
  // update time left every second

  useEffect(() => {
  if (Ref.current) {
    clearInterval(Ref.current);
  };
    const id = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeLeftInSeconds = lastClaimed + 604800 - currentTime;
      if (timeLeftInSeconds <= 0) {
        setTimeLeft(`0 days : 0 hours : 0 minutes : 0 seconds`);
      }else{
        const days = Math.floor(timeLeftInSeconds / 86400);
        const hours = Math.floor((timeLeftInSeconds % 86400) / 3600);
        const minutes = Math.floor(((timeLeftInSeconds % 86400) % 3600) / 60);
        const seconds = Math.floor(((timeLeftInSeconds % 86400) % 3600) % 60);
        setTimeLeft(`${days} days : ${hours} hours : ${minutes} minutes : ${seconds} seconds`);
      }
    }, 1000);
    Ref.current = id;
  }, [lastClaimed]);


  return (
    <div className="rounded-lg bg-gray-100 p-4">
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between text-xs font-semibold">
        <div className="uppercase">Time left</div>
      </div>{" "}
      <div className="mb-2 flex items-center justify-between uppercase">
        <div className="text-m">
          {
            timeLeft
          }
        </div>
      </div>{" "}
      <div className="flex items-center justify-between gap-2 text-xs uppercase">
        <div>Please wait to unstake</div>
      </div>
    </div>
  </div>
  );
}

export default CountLeft;