import React from "react";
import { Link } from "react-router-dom";

const Header = () => {

  return (
    <nav
    id="mainNav"
    className="navbar navbar-expand-lg navbar-sticky navbar-light"
  >
    <div className="container">
      <Link to="/" className="navbar-brand">
        <img
          src="https://firstdigitallabs.com/workspace/img/logo/logo-dark.svg"
          alt="First Digital Labs"
        />
      </Link>
      <ul className="navbar-nav navbar-nav-secondary order-lg-3">
        <li className="nav-item d-lg-none">
          <Link
            className="nav-link nav-icon"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
            to="/"
          >
            <span className="bi bi-list" />
          </Link>
        </li>
        <li className="nav-item d-none d-lg-block">
          <Link to="/contact" className="btn btn-black rounded-pill ms-2">
            Contact Us
          </Link>
        </li>
      </ul>
      <div
        className="collapse navbar-collapse"
        id="navbar"
        data-bs-parent="#mainNav"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" to='/about'>
              About Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  );
}

export default Header;