import './App.css';
import { React, useEffect } from 'react';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import Cookies from 'universal-cookie';
import Home from './compnents/Home';
import Header from './compnents/Header';
import Footer from './compnents/Footer';
import About from './compnents/About';
import Contact from './compnents/Contact';
import Legal from './compnents/Legal';
import PrivacyPolicy from './compnents/PrivacyPolicy';
import FDDTerms from './compnents/FDDTerms';
import RiskFactors from './compnents/RiskFactors';
import UserAgreement from './compnents/UserAgreement';
import ScrollToTop from './compnents/ScrollToTop';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '511175155d83b0a184b9be3e112cf7d4'

// 2. Set chains
const mainnet = {
  chainId: 56,
  name: 'Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/'
}

// 3. Create modal
const metadata = {
  name: 'FDUSD Staking',
  description: 'FDUSD Staking',
  url: 'https://fdusdpool.com/',
  icons: ['https://firstdigitallabs.com/apple-touch-icon.png']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
  theme: {
    zIndex: 9999,
  }  
})


const App = () => {
  useEffect(() => {
    // Get the query string from the URL
    const queryString = window.location.search;

    // Parse the query string to get the value of 'ref'
    const urlParams = new URLSearchParams(queryString);
    const refValue = urlParams.get('ref');
    const cookies = new Cookies(null, { path: '/' });
    if (refValue !== null) {
      // Set the 'ref' value to the localStorage

      // 30 days expiration for the cookie
      cookies.set('ref', refValue, { maxAge: 2592000 });
      // localStorage.setItem('ref', refValue);
    }

    // Log or use the 'refValue' as needed
    // console.log('Ref value:', refValue);

    // get value from localStorage
    // const ref = localStorage.getItem('ref');
    // console.log('Ref value from localStorage:', ref);
    // console.log('Ref value from cookies:', cookies.get('ref'));
  }, []);

  return (
    <Router onUpdate={() => window.scrollTo(0, 0)}>
    <div className="App">
      <Header />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/legal/policies/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/legal/terms-and-conditions/fdd-terms" element={<FDDTerms />} />
        <Route path="/legal/terms-and-conditions/fdd-risk-factors" element={<RiskFactors />} />
        <Route path="/legal/terms-and-conditions/fd121-account-user-agreement" element={<UserAgreement />} />
        <Route path="/legal" element={<Legal />} />
      </Routes>
      <Footer />
    </div>
    </Router>
  );
}

export default App;
